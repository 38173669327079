import React, { useEffect } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Company/HeroCompany.css";
import Lottie from "lottie-web";
import animationHeroCompany from "../../assets/animation/animationHeroCompany.json";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

interface HeroCompanyProps {}

const HeroCompany: React.FC<HeroCompanyProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    const container = document.getElementById("lottie-animation-three");

    if (container !== null) {
      const animation = Lottie.loadAnimation({
        container: container,
        renderer: "svg",
        animationData: animationHeroCompany,
        loop: true,
        autoplay: true,
      });

      return () => {
        animation.destroy();
      };
    }
  }, []);

  return (
    <>
      <Grid
        container
        className="container-hero-company"
        style={{
          height: "100%",
          flexDirection: md ? "row" : "column-reverse",
        }}
      >
        <Grid item className="box-hero-content-company" xs={12} md={6}>
          <Grid item xs={11}>
            <Typography
              variant="h1"
              className="title-hero-company color-primary"
            >
              Apri oggi la tua Srl
            </Typography>
            <Typography className="description-hero-company text-20 color-primary">
              Innovativa, ordinaria o semplificata? Se non sai cosa scegliere
              non preoccuparti, ti guideremo noi online in ogni passaggio.
            </Typography>

            <div className="container-button-company">
              <a
                className="nav-link button-cta-company button-bg-cta"
                href="https://app.legalfarm.it/sign-up?redirect=company"
              >
                <Typography
                  className="color-primary text-20"
                  style={{ fontWeight: 600 }}
                >
                  Apri la tua società
                </Typography>
              </a>
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4.2}
          className="container-animation-hero-company"
        >
          <div
            className="animation-hero-company"
            id="lottie-animation-three"
          ></div>
        </Grid>
      </Grid>
    </>
  );
};

export default HeroCompany;
