import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Homepage/TechSolution.css";

interface TechSolutionProps {}

const TechSolution: React.FC<TechSolutionProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      className="container-TechSolution bg-orange-dark"
      style={{ display: "flex" }}
    >
      <Grid
        item
        xs={10}
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h2" className="color-light title-TechSolution">
          La soluzione tech per la tua società
        </Typography>
        <Typography className="color-light description-TechSolution">
          Risparmia tempo, automatizza le attività legali e{" "}
          <span className="color-light" style={{ fontWeight: "bold" }}>
            paga solo quello che ti serve
          </span>
          .
        </Typography>

        <div className="container-button-TechSolution">
          <a
            className="nav-link button-cta-TechSolution"
            href="https://app.legalfarm.it/sign-up"
          >
            <Typography
              className="color-light text-20"
              style={{ fontWeight: 600 }}
            >
              Inizia ora gratis
            </Typography>
          </a>
        </div>
      </Grid>
    </Grid>
  );
};

export default TechSolution;
