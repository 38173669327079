import React, { useEffect, useState } from "react";
import HeroCompany from "../../components/Company/HeroCompany";
import MetricsCompany from "../../components/Company/MetricsCompany";
import HowCompany from "../../components/Company/HowCompany";
import AdvantagesCompany from "../../components/Company/AdvantagesCompany";
import CardPriceCompany from "../../components/Company/CardPriceCompany";
import FaqCompany from "../../components/Company/FaqCompany";
import ModalitySrl from "../../components/Company/ModalitySrl";
import CryptoSmart from "../../components/Company/CryptoSmart";
import CtaCompany from "../../components/Company/CtaCompany";
import Footer from "../../components/Footer/Footer";
import BannerCompany from "../../components/Company/BannerCompany";
import Navbar from "../../components/Navbar/Navbar";
import { Helmet } from "react-helmet";

const SrlPage: React.FC = () => {
  const [canonicalUrl, setCanonicalUrl] = useState("");

  useEffect(() => {
    setCanonicalUrl(window.location.origin + window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar />
      <HeroCompany />
      <MetricsCompany />
      <ModalitySrl />
      <HowCompany />
      <BannerCompany />
      <AdvantagesCompany />
      <CardPriceCompany />
      <CryptoSmart />
      <FaqCompany />
      <CtaCompany />
      <Footer />
    </>
  );
};

export default SrlPage;
