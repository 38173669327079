import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/ShareTransfer/WhySellWithUsShareTransfer.css";

interface WhySellWithUsShareTransferProps {}

const WhySellWithUsShareTransfer: React.FC<
  WhySellWithUsShareTransferProps
> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      className="container-AdvantagesShareTransfer"
      style={{ display: "flex", flexDirection: md ? "row" : "column-reverse" }}
    >
      <Grid
        item
        className="container-AdvantagesShareTransfer-content"
        xs={12}
        md={6}
      >
        <Grid
          item
          xs={9}
          sm={10}
          md={12}
          className="container-AdvantagesShareTransfer-text"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h2"
            className="title-AdvantagesShareTransfer color-primary"
          >
            Perché cedere le quote della tua Srl con noi
          </Typography>

          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={1}>
              <div className="icon-trophy"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="color-primary description-AdvantagesShareTransfer">
                Siamo il{" "}
                <span style={{ fontWeight: "bold" }} className="color-primary">
                  primo servizio in Italia
                </span>{" "}
                per la cessione di quote online, con avvio pratica immediato dal
                tuo pc o smartphone.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginTop: "50px" }}
          >
            <Grid item xs={1}>
              <div className="icon-automation"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="description-AdvantagesShareTransfer color-primary">
                Processo{" "}
                <span className="color-primary" style={{ fontWeight: "bold" }}>
                  interamente online e automatizzato
                </span>
                . <br /> Non ci piace perdere tempo e non vogliamo farne perdere
                a te.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginTop: "50px" }}
          >
            <Grid item xs={1}>
              <div className="icon-pig"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="description-AdvantagesShareTransfer color-primary">
                Costi competitivi e{" "}
                <span style={{ fontWeight: "bold" }}>risparmio garantito</span>.{" "}
                <br /> Grazie alla tecnologia, offriamo un servizio vantaggioso
                al massimo della qualità.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginTop: "50px" }}
          >
            <Grid item xs={1}>
              <div className="icon-easy-shareTransfer"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="description-AdvantagesShareTransfer color-primary">
                <span style={{ fontWeight: "bold" }}>Zero burocrazia</span> e{" "}
                <span style={{ fontWeight: "bold" }}>
                  assistenza professionale
                </span>
                . <br />
                Il nostro team segue la pratica a 360 gradi ed è sempre a tua
                disposizione.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "30px",
              justifyContent: "center",
            }}
          >
            <div className="container-button-cta-final">
              <a
                className="nav-link button-cta-final"
                href="https://app.legalfarm.it/sign-up?redirect=shareTransfer"
              >
                <Typography
                  className="color-primary text-20"
                  style={{ fontWeight: 600 }}
                >
                  Inizia subito
                </Typography>
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4.5} style={{ height: "100%" }}>
        <Grid item xs={12} className="container-img-AdvantagesShareTransfer">
          <div className="img-AdvantagesShareTransfer"></div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WhySellWithUsShareTransfer;
