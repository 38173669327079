import React, { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../../assets/css/Generics/FaqGlobal.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface CompanyFaqProps {
  PageFaq?: boolean;
}

const CompanyFaq: React.FC<CompanyFaqProps> = ({ PageFaq }) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {" "}
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Cos’è una Srl?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Srl è l’acronimo di società a responsabilità limitata. La
            caratteristica principale di questa società è la cosiddetta
            “autonomia patrimoniale perfetta”: il patrimonio della Srl è
            separato da quello dei soci. In caso di debiti, i creditori potranno
            aggredire solo il patrimonio sociale e non quello personale di ogni
            socio.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Cos'è una Srl semplificata (Srls)?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Si tratta di una Srl che può essere costituita con costi ridotti ma
            sconsigliata perché porta con sè notevoli limiti: <br />
          </Typography>
          <Typography className="color-orange-dark">
            <ul
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            >
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Statuto bloccato per legge che non consente di personalizzare
                  le regole sul funzionamento della società
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Capitale sociale massimo di €9.999 (spesso inadeguato ad
                  attrarre investitori)
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Soci solo persone fisiche e non anche altre società
                  (impossibilità di essere acquisiti o partecipati in futuro da
                  una società più grande e di monetizzare la cessione)
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Difficoltà a ottenere linee di credito e finanziamenti dalle
                  banche che tipicamente vedono nelle società semplificate un
                  veicolo poco affidabile
                </Typography>
              </li>
            </ul>
          </Typography>

          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Questi limiti portano spesso a trasformare una Srls in una Srl
            tradizionale, pagando nuovamente un atto notarile e altre imposte.
            Inoltre, è bene sottolineare come non ci siano agevolazioni fiscali
            e contabili rispetto a una Srl tradizionale: la tassazione e gli
            adempimenti sono identici.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Cos’è e come funziona una startup innovativa?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            È un particolare tipo di Srl che deve necessariamente avere come
            oggetto sociale (attività svolta) lo sviluppo, la realizzazione e la
            vendita di prodotti o servizi innovativi ad alto valore tecnologico.
            In virtù di ciò, questo tipo di società è premiata con agevolazioni
            fiscali e risparmio sulle imposte di costituzione. Oltre all’oggetto
            sociale innovativo, una startup deve avere almeno uno dei seguenti
            requisiti:
            <br />
          </Typography>
          <Typography className="color-orange-dark">
            <ul
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            >
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  spendere in ricerca e sviluppo almeno il 15% dei costi totali
                  annuali (oppure dei ricavi se questi ultimi a fine anno
                  risultano maggiori dei costi)
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  avere i 2/3 dei collaboratori con una laurea magistrale (non
                  triennale)
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  avere un brevetto registrato
                </Typography>
              </li>
            </ul>
          </Typography>

          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Il possesso del requisito scelto deve essere confermato di anno in
            anno.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Qual è il capitale sociale minimo per una Srl?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Basta soltanto €1 di capitale! Il limite minimo di €10.000, infatti,
            è stato rimosso già molti anni fa.
            <br />
            L’unico limite è sul capitale massimo e riguarda soltanto la Srl
            semplificata: €9.999. Non esiste, invece, un tetto massimo di
            capitale per Srl tradizionali e Startup innovative.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Cosa mi serve per aprire la pratica?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Bastano i dati e i documenti dei soci e degli amministratori della
            nuova società. Per completare la costituzione online, i soci e gli
            amministratori devono avere una propria firma digitale attiva
            (rilasciata da Aruba, Namirial o Infocert).
            <br />
            Non avete ancora una firma digitale? Nessun problema! Puoi
            richiederla sul nostro portale.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Quali sono i costi da versare al notaio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            <span style={{ fontWeight: "bold" }}>
              A) In caso di Srl semplificata (Srls): €930
            </span>
            <br />
            Dettaglio:
          </Typography>
          <Typography className="color-orange-dark">
            <ul
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            >
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Imposta di registro €200
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Diritti camerali €120
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Iscrizione a repertorio €2
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Onorario notaio €426,50
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Contributi Cassa Notariato €71,50
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  IVA su onorario e contributi €110
                </Typography>
              </li>
            </ul>
          </Typography>

          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Se la società ha un solo socio sono previsti ulteriori €90 di
            diritti camerali di segreteria.
          </Typography>
          <br />
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            <span style={{ fontWeight: "bold" }}>
              B) In caso di Srl tradizionale: €1.203,50
            </span>
            <br />
            Dettaglio:
          </Typography>
          <Typography className="color-orange-dark">
            <ul
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            >
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Imposta di registro €200
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Imposta di bollo €156
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Diritti camerali €120
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Diritti di segreteria €90
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Tassa archivio €27,50
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Iscrizione a repertorio €2
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Onorario notaio €426,50
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Contributi Cassa Notariato €71,50
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  IVA su onorari e contributi €110
                </Typography>
              </li>
            </ul>
          </Typography>

          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Se la società ha un solo socio sono previsti ulteriori €90 di
            diritti camerali di segreteria.
            <br />I costi notarili possono aumentare leggermente in caso di
            capitale sociale superiore a €37.000.
          </Typography>
          <br />
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            <span style={{ fontWeight: "bold" }}>
              C) In caso di Startup Innovativa: €837,50
            </span>
            <br />
            Dettaglio:
          </Typography>
          <Typography className="color-orange-dark">
            <ul
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            >
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Imposta di registro €200
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Tassa archivio €27,50
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Iscrizione a repertorio €2
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Onorario notaio €426,50
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Contributi Cassa Notariato €71,50
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  IVA su onorari e contributi €110
                </Typography>
              </li>
            </ul>
          </Typography>

          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Se la società ha un solo socio sono previsti ulteriori €90 di
            diritti camerali di segreteria.
            <br />I costi notarili possono aumentare leggermente in caso di
            capitale sociale superiore a €37.000.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Come funziona il processo?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Crei lo statuto direttamente sul nostro sito con un semplice modulo
            guidato. Dovrai poi versare il capitale sociale e attivare le firme
            digitali per i soci e gli amministratori che non ne hanno una. Non
            preoccuparti, riceverai tutte le istruzioni specifiche direttamente
            nella tua dashboard e in caso di dubbi potrai sempre scrivere al
            professionista che segue la pratica. Quando sarai pronto potrai
            procedere alla costituzione in videocall con il notaio.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Cosa è incluso nel servizio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Il nostro servizio include: <br />
          </Typography>
          <Typography className="color-orange-dark">
            <ul
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            >
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  la creazione online dello statuto della società
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  l’appuntamento con il notaio in videoconferenza
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  l’apertura della Partita IVA (a costituzione completata)
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  l’assistenza di un nostro professionista che supervisiona la
                  pratica
                </Typography>
              </li>
            </ul>
          </Typography>

          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Il servizio, invece, NON include:
            <br />
          </Typography>
          <Typography className="color-orange-dark">
            <ul
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            >
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  le firme digitali per i soci e gli amministratori che non ne
                  sono già in possesso. Puoi comunque richiederle comodamente
                  sul nostro portale
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  la casella PEC della società
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  per Srl tradizionali o Srls, la pratica di “dichiarazione di
                  inizio attività” per rendere la società attiva dopo
                  l’iscrizione al registro delle imprese (a cura del tuo
                  commercialista)
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-purple text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  ogni altro adempimento non strettamente connesso alla
                  costituzione
                </Typography>
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Posso utilizzare un mio modello di statuto?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            I costi pubblicizzati su questa pagina si riferiscono unicamente a
            costituzioni di società effettuate con il nostro modello di statuto
            (basato sul modello ministeriale). L’utilizzo di un tuo modello
            privato non garantirebbe l’applicazione dei costi notarili ridotti
            per legge e non conterrebbe le specifiche diciture obbligatorie per
            le costituzioni online. Ecco perché non è possibile utilizzarlo.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Quali sono le tempistiche per aprire una Srl?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Sono davvero molto brevi! Tutti i passaggi preliminari alla
            costituzione possono essere completati sul nostro sito in pochi
            minuti. I tempi possono leggermente dilatarsi nel caso in cui tu e i
            tuoi soci non abbiate già tutte le firme digitali attive. In genere,
            una firma digitale viene attivata in 24/48 ore. L’appuntamento per
            la videoconferenza con il notaio potrai fissarlo a stretto giro, non
            appena avrai completato tutti i passaggi preliminari.
            Le costituzioni di società non possono essere 
            effettuate nei periodi in cui l'attività notarile viene sospesa 
            (tipicamente dal 1 al 31 agosto e dal 20 dicembre al 10 gennaio).
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Come posso pagare?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Puoi acquistare il servizio pagando tramite PayPal (conto corrente
            associato o semplice carta di credito abilitata agli acquisti
            online).
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-purple" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Il servizio prevede un abbonamento?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-purple text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Assolutamente no! Paghi soltanto la singola pratica di costituzione
            della società.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CompanyFaq;
