import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Link, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/DigitalSignature/CardPriceDigitalSignature.css";

interface CardPriceDigitalSignatureProps {}

const CardPriceDigitalSignature: React.FC<
  CardPriceDigitalSignatureProps
> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="container-Price-digitalSignature bg-orange">
      <Grid
        item
        xs={12}
        container
        style={{
          justifyContent: "center",
          marginBottom: "70px",
        }}
      >
        <Grid
          item
          xs={12}
          md={8}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            className="color-light title-Price-shareTransfer"
            style={{ textAlign: "center" }}
          >
            Quanto costa?
          </Typography>
          <div className="container-subTitle-cardPrice-digitalSignature">
            <Typography className="color-light text-20">
              Firma accordi, contratti e atti con il tuo smartphone, basta un
              semplice <span style={{ fontWeight: "bold" }}>codice OTP</span>.
              Con il nostro servizio, rapido e certificato, avrai tutto ciò di
              cui hai bisogno per dare{" "}
              <span style={{ fontWeight: "bold" }}>pieno valore legale</span> ai
              tuoi documenti digitali.
            </Typography>
          </div>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        className="container-card-Price-digitalSignature"
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Grid item xs={10} md={5} className="Card-digitalSignature bg-light">
          <Typography
            variant="h5"
            className="title-card-digitalSignature color-orange"
          >
            Richiedi la tua firma
          </Typography>
          <Typography className="text-price-digitalSignature color-orange">
            €49{" "}
            <span className="text-info-price-digitalSignature color-orange">
              +IVA
            </span>
          </Typography>

          {/* lista  */}
          <Grid
            item
            xs={12}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              alignItems: "center",
              marginTop: "40px",
              textAlign: "start",
            }}
          >
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              <div className="icon-list-card"></div>
            </Grid>
            <Grid item xs={10}>
              <Typography className="text-card-digitalSignature color-orange">
                Scegli la tua firma
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              alignItems: "center",
              marginTop: "20px",
              textAlign: "start",
            }}
          >
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              <div className="icon-list-card"></div>
            </Grid>
            <Grid item xs={10}>
              <Typography className="text-card-digitalSignature color-orange">
                Affidati alla nostra procedura guidata
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              alignItems: "center",
              marginTop: "20px",
              textAlign: "start",
            }}
          >
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              <div className="icon-list-card"></div>
            </Grid>
            <Grid item xs={10}>
              <Typography className="text-card-digitalSignature color-orange">
                Ricevi l’assistenza di un professionista
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              alignItems: "center",
              marginTop: "20px",
              textAlign: "start",
            }}
          >
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              <div className="icon-list-card"></div>
            </Grid>
            <Grid item xs={10}>
              <Typography className="text-card-digitalSignature color-orange">
                Esegui in videocall il riconoscimento di identità
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              alignItems: "center",
              marginTop: "20px",
              textAlign: "start",
            }}
          >
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              <div className="icon-list-card"></div>
            </Grid>
            <Grid item xs={10}>
              <Typography className="text-card-digitalSignature color-orange">
                Ottieni la tua firma digitale
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              alignItems: "center",
              marginTop: "50px",
              marginBottom: "20px",
              textAlign: "start",
            }}
          >
            {" "}
            <a
              className="nav-link button-cta-orange"
              href="https://app.legalfarm.it/sign-up?redirect=digiSign"
            >
              <Typography
                className="color-orange text-20"
                style={{ fontWeight: 600 }}
              >
                Inizia subito
              </Typography>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardPriceDigitalSignature;
