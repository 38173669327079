import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Homepage/CardServices.css";

interface CardServicesProps {}

const CardServices: React.FC<CardServicesProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="bg-purple container-card-services">
      <Grid
        item
        xs={8}
        sm={10}
        md={10}
        lg={6}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h2" className="title-card-services color-light">
          Cosa puoi fare con noi
        </Typography>
        <Typography
          className="text-20 color-light description-width-cardService"
          style={{ textAlign: "center" }}
        >
          La nostra gamma di{" "}
          <span style={{ fontWeight: "bold" }}>
            soluzioni legali interamente online
          </span>{" "}
        </Typography>
        <Typography
          className="text-20 color-light description-width-cardService"
          style={{ textAlign: "center" }}
        >
          per facilitarti l'avvio e la gestione del tuo business.
        </Typography>
      </Grid>

      <Grid md={10} xs={12} item container className="box-card-service">
        {/* SRL  */}
        <Grid item xs={10} sm={5} md={5} lg={2.7} className="card-services">
          {" "}
          <Typography variant="h5" className="title-services color-purple">
            Apertura <br /> Srl
          </Typography>
          <div className="container-icon">
            <div className="icon-srl"></div>
          </div>
          <Typography className="color-purple">
            Scopri e apri con noi la Srl più adatta alle tue esigenze:
            ordinaria, semplificata o startup.
          </Typography>
          <div className="container-button-services">
            <a
              className="nav-link button-cta-services"
              href="/apertura-srl-online"
            >
              <Typography
                className="color-purple text-20"
                style={{ fontWeight: 600 }}
              >
                Inizia ora
              </Typography>
            </a>
          </div>
        </Grid>

        {/* CESSIONE  */}
        <Grid item xs={10} sm={5} md={5} lg={2.7} className="card-services">
          <Typography variant="h5" className="title-services color-purple">
            Cessione quote <br /> Srl
          </Typography>
          <div className="container-icon">
            <div className="icon-shareTransfer"></div>
          </div>
          <Typography className="color-purple">
            Trasferisci online le quote della tua Srl in pochi e semplici
            passaggi, senza passare dal notaio.
          </Typography>
          <div className="container-button-services">
            <a
              className="nav-link button-cta-services"
              href="/cessione-quote-srl-online"
            >
              <Typography
                className="color-purple text-20"
                style={{ fontWeight: 600 }}
              >
                Scopri come
              </Typography>
            </a>
          </div>
        </Grid>

        {/* MARCHIO  */}
        <Grid item xs={10} sm={5} md={5} lg={2.7} className="card-services">
          <Typography variant="h5" className="title-services color-purple">
            Registrazione <br /> marchio
          </Typography>
          <div className="container-icon">
            <div className="icon-trademark"></div>
          </div>
          <Typography className="color-purple">
            Proteggi i tuoi prodotti e servizi da contraffazioni e plagio,
            tutelando le tue idee e la tua identità.
          </Typography>
          <div className="container-button-services">
            <a
              className="nav-link button-cta-services"
              href="/registrazione-marchio-online"
            >
              <Typography
                className="color-purple text-20"
                style={{ fontWeight: 600 }}
              >
                Registra ora
              </Typography>
            </a>
          </div>
        </Grid>

        {/* FIRMA DIGITALE */}
        <Grid item xs={10} sm={5} md={5} lg={2.7} className="card-services">
          <Typography variant="h5" className="title-services color-purple">
            Attivazione firma <br /> digitale
          </Typography>
          <div className="container-icon">
            <div className="icon-sign"></div>
          </div>
          <Typography className="color-purple">
            Firma documenti dal tuo smartphone con la firma digitale certificata
            legalmente in Europa.
          </Typography>
          <div className="container-button-services">
            <a className="nav-link button-cta-services" href="/firma-digitale">
              <Typography
                className="color-purple text-20"
                style={{ fontWeight: 600 }}
              >
                Attiva subito
              </Typography>
            </a>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardServices;
