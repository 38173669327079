import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Trademark/MetricsTrademark.css";

interface MetricsTrademarkProps {}

const MetricsTrademark: React.FC<MetricsTrademarkProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="container-metrics-trademark bg-blue">
      <Grid item xs={12} className="container-content-metrics-trademark">
        <Grid
          item
          xs={10}
          md={3}
          className="card-metrics-trademark"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="icon-change" />
          <Typography
            className="color-light text-metrics-trademark"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            Risparmi oltre il 20%
          </Typography>
          <Typography
            className="color-light text-metrics-trademark"
            style={{ textAlign: "center" }}
          >
            rispetto ai prezzi di mercato.
          </Typography>
        </Grid>

        <Grid
          item
          xs={10}
          md={3}
          className="card-metrics-trademark"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="icon-online" />
          <Typography
            className="color-light text-metrics-trademark"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            Gestiamo tutto online
          </Typography>
          <Typography
            className="color-light text-metrics-trademark"
            style={{ textAlign: "center" }}
          >
            Nessuna presenza fisica richiesta.
          </Typography>
        </Grid>

        <Grid
          item
          xs={10}
          md={3}
          className="card-metrics-trademark"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="icon-prize" />
          <Typography
            className="color-light text-metrics-trademark"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            Soddisfatto o rimborsato
          </Typography>
          <Typography
            className="color-light text-metrics-trademark"
            style={{ textAlign: "center" }}
          >
            Garanzia su tutti i nostri servizi.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MetricsTrademark;
