import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Company/HowCompany.css";

interface HowCompanyProps {}

const HowCompany: React.FC<HowCompanyProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="bg-purple container-card-services-company">
      <Grid
        item
        xs={8}
        sm={10}
        md={10}
        lg={6}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          className="title-card-services-company color-light"
        >
          Come funziona il processo di costituzione
        </Typography>
      </Grid>

      <Grid
        md={10}
        xs={12}
        item
        container
        className="box-card-service-company"
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={10} className="bubbles-container">
          <Grid item xs={12} className="container-interno-HowCompany">
            <div
              className="bubble bubble-text first-bubble-company color-purple"
              style={{ width: "80px" }}
            >
              1
            </div>

            <div className="box-text">
              <p className="description-bubble p-0 m-0 color-light">
                Inserisci tutti i tuoi dati in un semplice modulo per{" "}
                <span style={{ fontWeight: "bold" }}>
                  creare il tuo statuto e l’atto costitutivo online
                </span>
              </p>
            </div>
          </Grid>

          <Grid item xs={12} className="container-interno-HowCompany">
            <div
              className="bubble bubble-text second-bubble-company color-purple"
              style={{ width: "80px" }}
            >
              2
            </div>
            <div className="box-text">
              <p className="description-bubble p-0 m-0 color-light">
                Carica nel nostro portale i documenti di tutti i soci e degli
                amministratori e{" "}
                <span style={{ fontWeight: "bold" }}>
                  costituisci con il notaio in videoconferenza
                </span>
              </p>
            </div>
          </Grid>

          <Grid item xs={12} className="container-interno-HowCompany">
            <div
              className="bubble bubble-text three-bubble-company color-purple"
              style={{ width: "80px" }}
            >
              3
            </div>
            <div className="box-text">
              <p className="description-bubble p-0 m-0 mt-3 color-light">
                {" "}
                Invio della pratica con{" "}
                <span style={{ fontWeight: "bold" }}>
                  iscrizione della società al Registro delle Imprese{" "}
                </span>
              </p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="container-interno-HowDoesThatShareTransfer"
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <a
                className="nav-link button-cta-white"
                href="https://app.legalfarm.it/sign-up?redirect=company"
              >
                <Typography
                  className="color-light text-20"
                  style={{ fontWeight: 600 }}
                >
                  Inizia subito
                </Typography>
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HowCompany;
