import React, { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../assets/css/Generics/FaqGlobal.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Close } from "@mui/icons-material";
import ShareTransferFaq from "../Generics/AccordionFaq/ShareTransferFaq";

interface FaqShareTransferProps {}

const FaqShareTransfer: React.FC<FaqShareTransferProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  // modale per faq cessione
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid
        container
        className="container-Faq bg-orange-dark"
        style={{ display: "flex" }}
      >
        <Grid
          item
          xs={10}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" className="color-light title-Faq">
            FAQ cessione quote Srl online
          </Typography>
        </Grid>

        <Grid item xs={10} md={8} className="container-accordion-faq">
          <Accordion
            style={{
              marginBottom: "40px",
              borderRadius: "5px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              style={{ padding: "5px 25px", borderRadius: "10px" }}
              expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
            >
              <Typography
                className="color-orange-dark text-20"
                style={{ fontWeight: "bold" }}
              >
                Cosa è incluso nel servizio?
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details-faq">
              <Typography
                className="color-orange-dark text-accordion-faq"
                style={{ marginBottom: "10px" }}
              >
                Il nostro servizio include: <br />
              </Typography>
              <Typography className="color-orange-dark">
                <ul className="color-orange-dark">
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      la creazione online dell’accordo di cessione delle quote
                      della società
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      l’assistenza di un nostro professionista che supervisiona
                      la pratica
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      l’invio della pratica agli enti competenti con il
                      conseguente rilascio della nuova visura della società con
                      la ripartizione delle quote aggiornata
                    </Typography>
                  </li>
                </ul>
              </Typography>
              <br />
              <Typography
                className="color-orange-dark text-accordion-faq"
                style={{ marginBottom: "10px" }}
              >
                Il servizio, invece, NON include: <br />
              </Typography>
              <Typography className="color-orange-dark text-accordion-faq">
                <ul className="color-orange-dark">
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      le firme digitali per le parti che non ne sono già in
                      possesso. Puoi comunque richiederle comodamente sul nostro
                      portale
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      la consulenza fiscale, contabile o generica
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      ogni altro adempimento non strettamente connesso
                      all’operazione di cessione (es. la comunicazione di
                      presenza/variazione del socio unico della società)
                      eventualmente richiedibile a preventivo.
                    </Typography>
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{
              marginBottom: "40px",
              borderRadius: "5px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              style={{ padding: "5px 25px", borderRadius: "10px" }}
              expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
            >
              <Typography
                className="color-orange-dark text-20"
                style={{ fontWeight: "bold" }}
              >
                Cosa mi serve per aprire la pratica?
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details-faq">
              <Typography className="color-orange-dark text-accordion-faq">
                Bastano i dati e i documenti delle parti, la visura aggiornata e
                lo statuto della società in questione e ogni altro documento
                utile per finalizzare la pratica (facilmente reperibile). Le
                quote oggetto della cessione non devono essere soggette a
                vincoli (es. pignoramenti o procedure fallimentari). Tutti i
                venditori e gli acquirenti devono avere una propria firma
                digitale attiva. <br />
                Tu e le altre parti non avete ancora una firma digitale? Nessun
                problema, puoi attivarla sul nostro portale.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{
              marginBottom: "40px",
              borderRadius: "5px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              style={{ padding: "5px 25px", borderRadius: "10px" }}
              expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
            >
              <Typography
                className="color-orange-dark text-20"
                style={{ fontWeight: "bold" }}
              >
                Quali sono le tempistiche per la cessione?
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details-faq">
              <Typography className="color-orange-dark text-accordion-faq">
                Sono davvero molto brevi! Tutti i passaggi preliminari possono
                essere completati sul nostro sito in pochi minuti. I tempi
                possono leggermente dilatarsi nel caso in cui tu e le altri
                parti non abbiate già tutte le firme digitali attive. In genere,
                una firma digitale viene attivata in 24/48 ore. La pratica viene
                inviata non appena avrai completato tutti i passaggi
                preliminari. I tempi per l’evasione dipendono interamente dagli
                enti competenti e in media sono di 7 giorni lavorativi. Vi sono
                possibili ritardi nell’invio/evasione della pratica nei periodi
                in cui l’attività degli enti competenti è limitata/interrotta
                (tipicamente, dall'1 al 31 agosto e dal 20 dicembre al 10
                gennaio). Questi ritardi, purtroppo, sono al di fuori del nostro
                controllo.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              onClick={handleOpenModal}
              component="a"
              className="color-light"
              style={{ cursor: "pointer" }}
            >
              Vedi tutte le domande frequenti sul servizio di cessione quote Srl
            </Typography>
          </div>
        </Grid>
      </Grid>

      {/* modal faq cessione  */}

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={openModal}>
          <Grid container sx={{ width: "80%" }}>
            <Grid
              item
              xs={12}
              sx={{
                position: "absolute",
                width: "80%",
                top: 100,
                left: "50%",
                transform: "translate(-50%, 0%)",
                bgcolor: "background.paper",
                border: "none",
                boxShadow: 24,
                borderRadius: "5px",
                maxHeight: "80vh",
                overflowY: "auto",
                padding: "20px 30px 30px 30px",
              }}
            >
              <Grid
                container
                style={{ marginTop: "20px", marginBottom: "50px" }}
              >
                <Grid item xs={8}>
                  {" "}
                  <Typography
                    className="color-orange-dark text-20"
                    style={{
                      fontWeight: "bold",
                      marginLeft: "15px",
                    }}
                  >
                    FAQ CESSIONE
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    className="close-modal-faq"
                    onClick={handleCloseModal}
                  >
                    <Close
                      className="color-orange-dark"
                      style={{ marginRight: "7px", marginTop: "-8px" }}
                    />
                  </IconButton>
                </Grid>
              </Grid>

              <div>
                <ShareTransferFaq />
              </div>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

export default FaqShareTransfer;
