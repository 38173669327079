import React, { useEffect, useState } from "react";
import BlogContainer from "../components/Blog/BlogContainer";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";

const Blog: React.FC = () => {
  const [canonicalUrl, setCanonicalUrl] = useState("");

  useEffect(() => {
    setCanonicalUrl(window.location.origin + window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar />
      <BlogContainer />
      <Footer />
    </>
  );
};

export default Blog;
