import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Homepage/WhyChooseUs.css";

interface WhyChooseUsProps {}

const WhyChooseUs: React.FC<WhyChooseUsProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      className="container-whoChooseUs"
      style={{ display: "flex", flexDirection: md ? "row" : "column-reverse" }}
    >
      <Grid item className="container-whyChoose-content" xs={12} md={6}>
        <Grid
          item
          xs={9}
          sm={10}
          md={12}
          className="container-whyChoose-text"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" className="title-whoChooseUs color-primary">
            Perché sceglierci
          </Typography>

          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={1}>
              <div className="icon-coin"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="color-primary description-whoChooseUs">
                Per{" "}
                <span style={{ fontWeight: "bold" }} className="color-primary">
                  risparmiare tempo e costi
                </span>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginTop: "50px" }}
          >
            <Grid item xs={1}>
              <div className="icon-time"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="description-whoChooseUs color-primary">
                Per generare in pochi minuti pratiche complesse,{" "}
                <span className="color-primary" style={{ fontWeight: "bold" }}>
                  evitando lunghi passaggi burocratici
                </span>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginTop: "50px" }}
          >
            <Grid item xs={1}>
              <div className="icon-home-whyChoose"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="description-whoChooseUs color-primary">
                Per gestire{" "}
                <span style={{ fontWeight: "bold" }}>
                  comodamente da casa o dall'ufficio
                </span>{" "}
                gli aspetti legali della tua società
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4.5} style={{ height: "100%" }}>
        <Grid item xs={12} className="container-img-whyChooseUs">
          <div className="img-whyChooseUs"></div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WhyChooseUs;
