import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/AboutUs/SectionOne.css";

interface TrustPilotAboutUsProps {}

const TrustPilotAboutUs: React.FC<TrustPilotAboutUsProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="container-TheyChoseUs bg-orange">
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h2" className="color-light title-TheyChoseUs">
          Ci hanno già scelto
        </Typography>
      </Grid>

      <Grid
        item
        container
        xs={12}
        className="container-card-TheyChoose"
        style={{
          display: "flex",
          justifyContent: md ? "space-between" : "center",
        }}
      >
        <Grid
          item
          xs={10}
          sm={8}
          md={3.5}
          lg={3.5}
          className="card-TheyChooseUs"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="icon-review"></div>
          </div>
          <Grid item container xs={12}>
            <Typography className="text-card-TheyChoose color-orange">
              "Disponibili e professionali: pratiche sbrigate in pochi minuti,
              facendo tutto online. Più che fantastico".
            </Typography>
            <div
              style={{
                position: "absolute",
                bottom: "30px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="icon-woman"></div>
              </div>
              <Typography
                className="color-orange text-info-theyChoose"
                style={{ fontWeight: "bold" }}
              >
                Alessandra G.
              </Typography>
              <Typography className="color-orange text-info-theyChoose">
                Freelance Marketing Specialist
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          xs={10}
          sm={8}
          md={3.5}
          lg={3.5}
          className="card-TheyChooseUs"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="icon-review"></div>
          </div>
          <Grid item container xs={12}>
            <Typography className="text-card-TheyChoose color-orange">
              "Grande soddisfazione! La soluzione proposta è un'alternativa
              molto più conveniente e veloce di quelle classiche del notaio o
              del commercialista".
            </Typography>
            <div
              style={{
                position: "absolute",
                bottom: "30px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="icon-man"></div>
              </div>
              <Typography
                className="color-orange text-info-theyChoose"
                style={{ fontWeight: "bold" }}
              >
                Bruno P.
              </Typography>
              <Typography className="color-orange text-info-theyChoose">
                CEO Loquis
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          xs={10}
          sm={8}
          md={3.5}
          lg={3.5}
          className="card-TheyChooseUs"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="icon-review"></div>
          </div>
          <Grid item container xs={12}>
            <Typography className="text-card-TheyChoose color-orange">
              "Veloci, precisi, accurati. Per la pratica di cessione quote ho
              speso la metà di quanto mi era stato preventivato altrove".
            </Typography>
            <div
              style={{
                position: "absolute",
                bottom: "30px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="icon-man"></div>
              </div>
              <Typography
                className="color-orange text-info-theyChoose"
                style={{ fontWeight: "bold" }}
              >
                Luca C.
              </Typography>
              <Typography className="color-orange text-info-theyChoose">
                CEO Reactive
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={6} className="container-logo-trustpilot-TheyChoose">
          <div className="logo-trustpilot-TheyChoose"></div>
          <a
            href="https://it.trustpilot.com/review/legalfarm.it"
            target="_blank"
            className="color-light"
            style={{ textAlign: "center", marginTop: "-60px" }}
          >
            <Typography className="color-light">
              Vedi tutte le recensioni
            </Typography>
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrustPilotAboutUs;
