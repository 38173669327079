import React, { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../../assets/css/Generics/FaqGlobal.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Close } from "@mui/icons-material";

interface ShareTransferFaqProps {
  PageFaq?: boolean;
}

const ShareTransferFaq: React.FC<ShareTransferFaqProps> = ({ PageFaq }) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Cos’è la cessione quote di una Srl?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Si tratta dell’operazione con cui uno o più soci cedono la propria
            quota (o parte di essa) ad altri soci oppure a terzi estranei alla
            società. L’operazione viene regolata all’interno di un accordo
            scritto sottoscritto da tutte le parti.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Serve un notaio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Assolutamente no! L’operazione di cessione può essere finalizzata
            anche con un semplice accordo tra le parti, risparmiando i costi di
            un atto pubblico notarile.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Serve un commercialista?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Sì, il commercialista è il professionista necessario per la
            conclusione della cessione. Per questo motivo la tua pratica sarà
            depositata da un commercialista, incluso nel servizio.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Cosa mi serve per aprire la pratica?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Bastano i dati e i documenti delle parti, la visura aggiornata e lo
            statuto della società in questione e ogni altro documento utile per
            finalizzare la pratica (facilmente reperibile). Le quote oggetto
            della cessione non devono essere soggette a vincoli (es.
            pignoramenti o procedure fallimentari). Tutti i venditori e gli
            acquirenti devono avere una propria firma digitale attiva. <br />
            Tu e le altre parti non avete ancora una firma digitale? Nessun
            problema, puoi attivarla sul nostro portale.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Quali sono le imposte dovute e le possibili variazioni del prezzo?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Le imposte dovute sono le seguenti: <br />
          </Typography>
          <Typography className="color-orange-dark">
            <ul
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            >
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-orange text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Imposta di registro (Agenzia delle entrate) €200
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-orange text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Imposta di bollo (Agenzia delle entrate) €15
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-orange text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Diritti di segreteria (Camera di commercio) €90
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-orange text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Imposta di bollo (Camera di commercio) €65
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-orange text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  Tariffa di deposito €2,44
                </Typography>
              </li>
            </ul>
          </Typography>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "20px" }}
          >
            <span style={{ fontWeight: "bold" }}>
              Totale sole imposte €372,44
            </span>{" "}
            <br />
            per 1 singola operazione di cessione
          </Typography>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Nel caso di più operazioni di cessione,{" "}
            <span style={{ fontWeight: "bold" }}>
              per ogni operazione aggiuntiva
            </span>{" "}
            sono previsti ulteriori{" "}
            <span style={{ fontWeight: "bold" }}>€200</span> di imposta di
            registro e <span style={{ fontWeight: "bold" }}>€150+IVA</span> sul
            prezzo base della pratica.{" "}
            <span style={{ fontWeight: "bold" }}>Esempio</span>: se l’unica
            operazione presente nell’atto di cessione riguarda il soggetto A che
            cede la propria quota al soggetto B allora non ci sarà nessun
            sovrapprezzo. Se invece ci sono più venditori o più acquirenti
            allora per ogni operazione di cessione aggiuntiva alla prima
            l’imposta di registro aumenterà di €200 e il prezzo base del nostro
            servizio aumenterà di €150+IVA. <br />
            Non preoccuparti! Il{" "}
            <span style={{ fontWeight: "bold" }}>
              nostro sistema elabora in automatico il costo totale
            </span>{" "}
            in base al n. di operazioni che vuoi effettuare.
          </Typography>{" "}
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Come funziona il processo?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Crei l’accordo di cessione direttamente sul nostro sito con un
            semplice modulo guidato. Dovrai poi attivare le firme digitali per i
            venditori e gli acquirenti delle quote che non ne hanno una. Non
            preoccuparti, riceverai tutte le istruzioni specifiche direttamente
            nella tua dashboard e in caso di dubbi potrai sempre scrivere al
            professionista che segue la pratica. Quando sarai pronto potremo
            inviare la pratica agli enti competenti e inviarti la visura
            d’evasione.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Come avviene la tassazione sulla cessione quote di una Srl?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            A grandi linee, è prevista una tassazione del 26% sulla eventuale
            plusvalenza che il venditore realizza. Possono esserci delle
            eccezioni a questa regola in base ad ogni caso concreto che potrai
            verificare a pratica completata con il tuo commercialista di
            fiducia.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Cosa è incluso nel servizio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Il nostro servizio include: <br />
          </Typography>

          <Typography className="color-orange-dark">
            <ul
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            >
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-orange text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  la creazione online dell’accordo di cessione delle quote della
                  società
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-orange text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  l’assistenza di un nostro professionista che supervisiona la
                  pratica
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-orange text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  l’invio della pratica agli enti competenti con il conseguente
                  rilascio della nuova visura della società con la ripartizione
                  delle quote aggiornata
                </Typography>
              </li>
            </ul>
          </Typography>
          <br />
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Il servizio, invece, NON include: <br />
          </Typography>
          <Typography className="color-orange-dark">
            <ul
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            >
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-orange text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  le firme digitali per le parti che non ne sono già in
                  possesso. Puoi comunque richiederle comodamente sul nostro
                  portale
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-orange text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  la consulenza fiscale, contabile o generica
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    PageFaq === true
                      ? "color-orange text-accordion-faq"
                      : "color-orange-dark text-accordion-faq"
                  }
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  ogni altro adempimento non strettamente connesso
                  all’operazione di cessione (es. la comunicazione di
                  presenza/variazione del socio unico della società)
                  eventualmente richiedibile a preventivo.
                </Typography>
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Quali sono le tempistiche per la cessione?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Sono davvero molto brevi! Tutti i passaggi preliminari possono
            essere completati sul nostro sito in pochi minuti. I tempi possono
            leggermente dilatarsi nel caso in cui tu e le altri parti non
            abbiate già tutte le firme digitali attive. In genere, una firma
            digitale viene attivata in 24/48 ore. La pratica viene inviata non
            appena avrai completato tutti i passaggi preliminari. I tempi per
            l’evasione dipendono interamente dagli enti competenti e in media
            sono di 7 giorni lavorativi. Vi sono possibili ritardi
            nell’invio/evasione della pratica nei periodi in cui l’attività
            degli enti competenti è limitata/interrotta (tipicamente, dall'1 al
            31 agosto e dal 20 dicembre al 10 gennaio). Questi ritardi,
            purtroppo, sono al di fuori del nostro controllo.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Come posso pagare?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Puoi acquistare il servizio pagando tramite PayPal (conto corrente
            associato o semplice carta di credito abilitata agli acquisti
            online).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-orange" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Il servizio prevede un abbonamento?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-orange text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Assolutamente no! Paghi soltanto la singola pratica di cessione
            quote.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ShareTransferFaq;
