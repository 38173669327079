import React, { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../assets/css/Company/FaqCompany.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Close } from "@mui/icons-material";
import CompanyFaq from "../Generics/AccordionFaq/CompanyFaq";

interface FaqCompanyProps {}

const FaqCompany: React.FC<FaqCompanyProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  // modale
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid
        container
        className="container-Faq bg-orange-dark"
        style={{ display: "flex" }}
      >
        <Grid
          item
          xs={10}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" className="color-light title-Faq">
            FAQ
          </Typography>
        </Grid>

        <Grid item xs={10} md={8} className="container-accordion-faq">
          <Accordion
            style={{
              marginBottom: "40px",
              borderRadius: "5px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              style={{ padding: "5px 25px", borderRadius: "10px" }}
              expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
            >
              <Typography
                className="color-orange-dark text-20"
                style={{ fontWeight: "bold" }}
              >
                Cosa è incluso nel servizio?
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details-faq">
              <Typography
                className="color-orange-dark text-accordion-faq"
                style={{ marginBottom: "10px" }}
              >
                Il nostro servizio include: <br />
              </Typography>
              <Typography className="color-orange-dark">
                <ul className="color-orange-dark">
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      la creazione online dello statuto della società
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      l’appuntamento con il notaio in videoconferenza
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      l’apertura della Partita IVA (a costituzione completata)
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      l’assistenza di un nostro professionista che supervisiona
                      la pratica
                    </Typography>
                  </li>
                </ul>
              </Typography>
              <br />
              <Typography
                className="color-orange-dark text-accordion-faq"
                style={{ marginBottom: "10px" }}
              >
                Il servizio, invece, NON include: <br />
              </Typography>
              <Typography className="color-orange-dark text-accordion-faq">
                <ul className="color-orange-dark">
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      le firme digitali per i soci e gli amministratori che non
                      ne sono già in possesso. Puoi comunque richiederle
                      comodamente sul nostro portale
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      la casella PEC della società
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      per Srl tradizionali o Srls, la pratica di “dichiarazione
                      di inizio attività” per rendere la società attiva dopo
                      l’iscrizione al registro delle imprese (a cura del tuo
                      commercialista)
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange-dark text-accordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      ogni altro adempimento non strettamente connesso alla
                      costituzione
                    </Typography>
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{
              marginBottom: "40px",
              borderRadius: "5px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              style={{ padding: "5px 25px", borderRadius: "10px" }}
              expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
            >
              <Typography
                className="color-orange-dark text-20"
                style={{ fontWeight: "bold" }}
              >
                Cosa mi serve per aprire la pratica?
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details-faq">
              <Typography className="color-orange-dark text-accordion-faq">
                Bastano i dati e i documenti dei soci e degli amministratori
                della nuova società. Per completare la costituzione online, i
                soci e gli amministratori devono avere una propria firma
                digitale attiva (rilasciata da Aruba, Namirial o Infocert).{" "}
                <br />
                Non avete ancora una firma digitale? Nessun problema! Puoi
                richiederla sul nostro portale.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{
              marginBottom: "40px",
              borderRadius: "5px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              style={{ padding: "5px 25px", borderRadius: "10px" }}
              expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
            >
              <Typography
                className="color-orange-dark text-20"
                style={{ fontWeight: "bold" }}
              >
                Quali sono le tempistiche per aprire una Srl?
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details-faq">
              <Typography className="color-orange-dark text-accordion-faq">
                Sono davvero molto brevi! Tutti i passaggi preliminari alla
                costituzione possono essere completati sul nostro sito in pochi
                minuti. I tempi possono leggermente dilatarsi nel caso in cui tu
                e i tuoi soci non abbiate già tutte le firme digitali attive. In
                genere, una firma digitale viene attivata in 24/48 ore.
                L’appuntamento per la videoconferenza con il notaio potrai
                fissarlo a stretto giro, non appena avrai completato tutti i
                passaggi preliminari.Le costituzioni di società non possono essere 
                effettuate nei periodi in cui l'attività notarile viene sospesa 
                (tipicamente dal 1 al 31 agosto e dal 20 dicembre al 10 gennaio).
              </Typography>
            </AccordionDetails>
          </Accordion>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              onClick={handleOpenModal}
              component="a"
              className="color-light"
              style={{ cursor: "pointer" }}
            >
              Vedi tutte le domande frequenti sul servizio di apertura Srl
            </Typography>
          </div>
        </Grid>
      </Grid>

      {/* modal faq cessione  */}

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={openModal}>
          <Grid container sx={{ width: "80%" }}>
            <Grid
              item
              xs={12}
              sx={{
                position: "absolute",
                width: "80%",
                top: 100,
                left: "50%",
                transform: "translate(-50%, 0%)",
                bgcolor: "background.paper",
                border: "none",
                boxShadow: 24,
                borderRadius: "5px",
                maxHeight: "80vh",
                overflowY: "auto",
                padding: "20px 30px 30px 30px",
              }}
            >
              <Grid
                container
                style={{ marginTop: "20px", marginBottom: "50px" }}
              >
                <Grid item xs={8}>
                  {" "}
                  <Typography
                    className="color-orange-dark text-20"
                    style={{
                      fontWeight: "bold",
                      marginLeft: "15px",
                    }}
                  >
                    FAQ APERTURA SRL
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    className="close-modal-faq"
                    onClick={handleCloseModal}
                  >
                    <Close
                      className="color-orange-dark"
                      style={{ marginRight: "7px", marginTop: "-8px" }}
                    />
                  </IconButton>
                </Grid>
                <div style={{ marginTop: "50px" }}>
                  <CompanyFaq />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

export default FaqCompany;
