import { createTheme } from "@mui/material";

const MuiTheme = createTheme({
  typography: {
    fontFamily: `'Poppins', sans-serif`,
  },
  palette: {
    primary: {
      main: "#1666b0",
      dark: "#4A4A4A",
    },
  },
  breakpoints: {},
});

export default MuiTheme;
