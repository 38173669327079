import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Company/BannerCompany.css";

interface BannerCompanyProps {}

const BannerCompany: React.FC<BannerCompanyProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="fixed-bottom-banner">
      <Grid
        item
        xs={12}
        // className="bg-primary"
        style={{
          minHeight: "100px",
          width: "100%",
          display: "flex",
          padding: "15px",
          backgroundColor: '#fff'
        }}
      >
        <Grid item xs={1} className="container-icon-banner-company">
          <div className="icon-warning-banner-company" />
        </Grid>

        <Grid item xs={11} className="container-copy-banner-company">
          <Typography className="color-primary text-banner-company">
            La procedura di costituzione online è attualmente disponibile solo
            per società con <span style={{fontWeight: 'bold'}}>almeno 1 socio residente in Lombardia</span> o tutti i soci
            residenti all’estero.
            <br /> Stiamo lavorando per estendere il servizio.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BannerCompany;
