import React, { useEffect } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Homepage/HeroHome.css";
import Lottie from "lottie-web";
import animationHero from "../../assets/animation/animationHero.json";

interface HeroHomeProps {}

const HeroHome: React.FC<HeroHomeProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    const container = document.getElementById("lottie-animation-three");

    if (container !== null) {
      const animation = Lottie.loadAnimation({
        container: container,
        renderer: "svg",
        animationData: animationHero,
        loop: true,
        autoplay: true,
      });

      return () => {
        animation.destroy();
      };
    }
  }, []);

  return (
    <Grid
      container
      className="container-hero"
      style={{
        height: "100%",
        flexDirection: md ? "row" : "column-reverse",
      }}
    >
      <Grid item className="box-hero-content" xs={12} md={6}>
        <Grid item xs={10}>
          <Typography variant="h1" className="title-hero color-primary">
            Cambia il tuo modo di fare business
          </Typography>
          <Typography className="description-hero text-20 color-primary">
            Tutti i <span style={{ fontWeight: "bold" }}>servizi legali</span>{" "}
            di cui hai bisogno per la tua attività{" "}
            <span style={{ fontWeight: "bold" }}>interamente online</span>, in
            modo veloce, pratico e sicuro.
          </Typography>

          <div className="container-button">
            <a
              className="nav-link button-cta button-bg-cta"
              href="https://app.legalfarm.it/sign-up"
            >
              <Typography
                className="color-primary text-20"
                style={{ fontWeight: 600 }}
              >
                Inizia ora gratis
              </Typography>
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4.2} className="container-animation-hero">
        <div className="animation-hero" id="lottie-animation-three"></div>
      </Grid>
    </Grid>
  );
};

export default HeroHome;
