import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import BlogDetail from "../components/Blog/BlogDetail";
import { Helmet } from "react-helmet";

const BlogDetailPage: React.FC = () => {
  const currentUrl = window.location.href;

  const [canonicalUrl, setCanonicalUrl] = useState("");

  useEffect(() => {
    setCanonicalUrl(window.location.origin + window.location.pathname);
  }, []);

  return (
    <>
      {currentUrl === "https://www.legalfarm.it/b/tassazione-srl" ||
        (currentUrl === "https://legalfarm.it/b/tassazione-srl" && (
          <Helmet>
            <link rel="canonical" href={canonicalUrl} />
          </Helmet>
        ))}
      <Navbar />
      <BlogDetail />
    </>
  );
};

export default BlogDetailPage;
