import React from "react";

import { Grid, Typography } from "@mui/material";
import "../../src/assets/css/Terms.css";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";

const TermsConditions: React.FC = () => {
  return (
    <>
      <Navbar />
      <div>
        {/* <h1>Termini e condizioni</h1> */}

        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Grid item xs={9}>
            {/* title  */}
            <Typography className="text-title" style={{ marginBottom: "30px" }}>
              Condizioni generali di utilizzo della Piattaforma
            </Typography>
            {/* 1 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              1. Premesse e definizioni
            </Typography>

            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>1.1</span> Le presenti
              condizioni generali di utilizzo (le “
              <span style={{ fontWeight: "bold" }}>Condizioni Generali</span>”)
              sono volte a disciplinare l’utilizzo da parte dell’utente (l’“
              <span style={{ fontWeight: "bold" }}>Utente</span>”) del sito
              internet www.legalfarm.it (la “
              <span style={{ fontWeight: "bold" }}>Piattaforma</span>”)
              sviluppato e gestito da Costituzione Srl società a responsabilità
              limitata, con sede legale in Milano (MI), Via del Lauro 9, codice
              fiscale e partita IVA n. 10353830960, capitale sociale €52.900,00
              i.v., indirizzo pec costituzionesrl@pec.lexia.it (il “
              <span style={{ fontWeight: "bold" }}>Titolare</span>”), nonché
              l’acquisto tramite la Piattaforma dei servizi offerti dal Titolare
              come descritti al successivo articolo 2.
            </Typography>

            {/* 1.2 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>1.2</span> L’accesso alla
              Piattaforma e/o la fruizione dei servizi messi a disposizione dal
              Titolare per il tramite della Piattaforma o in relazione alla
              stessa sono subordinati al rispetto e all’accettazione, da parte
              dell’Utente, delle presenti Condizioni Generali e di qualsiasi
              ulteriore addendum, integrazione contrattuale, comunicazione o
              notifica che dovessero essere richiesti, trasmessi o messi a
              disposizione dal Titolare a propria discrezione. Accedendo alla
              Piattaforma e utilizzando i servizi, l’Utente riconosce di aver
              letto attentamente e compreso pienamente i termini riportati nelle
              presenti Condizioni Generali e accetta di essere vincolato alle
              stesse. La mancata accettazione delle presenti Condizioni Generali
              comporta l’impossibilità di accedere alla Piattaforma e di fruire
              dei Servizi.
            </Typography>

            {/* 1.3 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>1.3</span> Lo status di
              Utente è acquisito una volta completato il processo di
              registrazione alla Piattaforma, nel rispetto della procedura a tal
              fine prevista e dopo aver fornito le informazioni appositamente
              richieste dalla Piattaforma. Il Titolare si riserva il diritto di
              non accettare, a proprio insindacabile giudizio, le richieste di
              registrazione presentate tramite la Piattaforma, nonché di
              aggiornare e/o modificare, di volta in volta, i requisiti per
              l’adesione alla Piattaforma e/o per il mantenimento dello status
              di Utente, senza alcun obbligo di informativa preventiva o
              successiva nei confronti dell’Utente stesso.
            </Typography>

            {/* 2 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              2. Oggetto ed esecuzione dei Servizi
            </Typography>

            {/* 2.1 */}
            <Typography className="text-terms" style={{ marginBottom: "10px" }}>
              <span style={{ marginRight: "5px" }}>2.1</span> Il Titolare mette
              a disposizione dell’Utente, attraverso la Piattaforma, questionari
              a compilazione guidata e procedure tecnologiche interamente o
              parzialmente automatizzate, per supportare e/o consentire
              all’Utente il completamento da remoto di adempimenti legali,
              fiscali, tecnici e burocratici in genere, connessi all’avvio, alla
              gestione e alla modifica di attività di impresa (complessivamente,
              i “<span style={{ marginRight: "5px" }}>Servizi</span>” e,
              singolarmente, il “
              <span style={{ marginRight: "5px" }}>Servizio</span>”), quali:{" "}
            </Typography>
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              {" "}
              a) la costituzione di società a responsabilità limitata, anche
              semplificate e/o startup innovative;
              <br /> b) la registrazione di marchi;
              <br /> c) la cessione di quote di società a responsabilità
              limitata, anche semplificate e/o startup innovative;
              <br /> d) payroll (gestione del personale);
              <br /> e) rilascio di firme digitali remote;
              <br /> f) ogni altro servizio di volta in volta indicato come
              disponibile nella Piattaforma.
            </Typography>

            {/* 2.2 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>2.2</span> Qualsiasi
              comunicazione e/o informazione pubblicata sulla Piattaforma non è
              da intendersi in nessun caso alla stregua di una raccomandazione
              personalizzata e/o consulenza legale.
            </Typography>

            {/* 2.3 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>2.3</span> Il Titolare ha la
              facoltà di avvalersi, sotto la propria completa responsabilità,
              della collaborazione di uno o più avvocati, commercialisti e/o
              consulenti per l’esecuzione dei Servizi (i “
              <span style={{ fontWeight: "bold" }}>Professionisti</span>”). I
              Professionisti supporteranno gli Utenti nel completamento degli
              adempimenti connessi ai Servizi acquistati.
            </Typography>

            {/* 2.4 */}
            <Typography className="text-terms" style={{ marginBottom: "10px" }}>
              <span style={{ marginRight: "5px" }}>2.4</span> I Servizi offerti
              dal Titolare tramite la Piattaforma si dividono in:
            </Typography>
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              (i){" "}
              <span style={{ fontWeight: "bold" }}>
                servizi a prezzi standard
              </span>
              : nella pagina di presentazione di tali servizi viene indicato lo
              specifico prezzo per l’acquisto e le eventuali variazioni del
              prezzo in base a alle scelte effettuate dall’Utente nella
              compilazione dei questionari (i “
              <span style={{ fontWeight: "bold" }}>Servizi Standard</span>”).
              <br /> (ii){" "}
              <span style={{ fontWeight: "bold" }}>servizi a preventivo</span>:
              nella pagina di presentazione di tali servizi non viene indicato
              un prezzo standard. L’Utente avrà la facoltà di richiedere
              gratuitamente un preventivo personalizzato basato sulle sue
              effettive richieste e necessità (i “
              <span style={{ fontWeight: "bold" }}>Servizi a Preventivo</span>
              ”).
            </Typography>

            {/* 2.5 */}
            <Typography className="text-terms" style={{ marginBottom: "10px" }}>
              <span style={{ marginRight: "5px" }}>2.5</span> L’erogazione dei
              Servizi Standard prevede che i Professionisti non offrano
              consulenza e/o pareri legali in relazione a esigenze, informazioni
              e scelte indicate dall’Utente nei questionari a compilazione
              guidata e nelle procedure tecnologiche interamente o parzialmente
              automatizzate. La responsabilità di tali informazioni e scelte
              ricade esclusivamente sull’Utente stesso. I Professionisti si
              limitano a:
            </Typography>
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              a) supervisionare la corretta erogazione del Servizio;
              <br /> b) eseguire i passaggi operativi necessari per lo specifico
              servizio acquistato dall’Utente (es. invio di documenti a enti o
              professionisti competenti);
              <br /> c) rispondere ai dubbi di natura operativa che l’Utente
              eventualmente sollevi, tramite l’apposita funzione di invio
              messaggi messa a disposizione dell’Utente nella propria dashboard.
            </Typography>

            {/* 2.6 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>2.6</span> È comunque facoltà
              dell’Utente che acquista un Servizio Standard richiedere al
              Professionista a lui assegnato una consulenza qualificata e/o
              l’erogazione di un servizio non espressamente incluso tra i
              Servizi della Piattaforma. In questi casi, il Professionista
              presenterà all’Utente un preventivo per l’attività richiesta.
              L’Utente resta libero di accettare o meno tale preventivo.
            </Typography>

            {/* 2.7 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>2.7</span> Il Titolare si
              riserva il diritto di nominare e/o avvalersi di servizi e/o
              prodotti offerti da terze parti per l’esecuzione di uno o più
              Servizi. In questi casi, si applicheranno all’Utente le condizioni
              di contratto delle terze parti, previa accettazione delle stesse
              da parte dell’Utente.
            </Typography>

            {/* 3 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              3. Registrazione alla Piattaforma, creazione dell’Account e
              sicurezza
            </Typography>

            {/* 3.1 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>3.1</span> Per usufruire dei
              Servizi, l’Utente dovrà preliminarmente registrarsi alla
              Piattaforma creando un account nella sezione dedicata della
              Piattaforma, fornendo tutte le informazioni personali richieste
              tempo per tempo dal Titolare (es. nome, cognome, email) (l’“
              <span style={{ fontWeight: "bold" }}>Account</span>”). Il Titolare
              non è responsabile della veridicità e della correttezza delle
              informazioni fornite dall’Utente in sede di registrazione.
            </Typography>

            {/* 3.2 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>3.2</span> La creazione di un
              Account è riservata solo all’Utente che abbia compiuto il
              diciottesimo anno di età. In caso di mancato rispetto da parte
              dell’Utente del requisito della maggiore età, la Piattaforma
              impedirà all’Utente di registrare il proprio Account.
            </Typography>

            {/* 3.3 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>3.3</span> Al fine di
              accedere all’Account, l’Utente dovrà utilizzare delle credenziali
              di accesso, composte da (i) un codice di identificazione cliente
              (e-mail) indicato dall’Utente in fase di registrazione alla
              Piattaforma, (ii) una parola chiave (password) scelta dall’Utente
              (le “
              <span style={{ fontWeight: "bold" }}>Credenziali di Accesso</span>
              ”).
            </Typography>

            {/* 3.4 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>3.4</span> Resta inteso che
              il Titolare potrà rifiutare la richiesta di iscrizione alla
              Piattaforma a proprio insindacabile giudizio e senza obbligo di
              motivazione, ogni qual volta ciò si renda necessario in
              ottemperanza di obblighi di legge, regolamenti o provvedimenti
              delle Autorità.
            </Typography>

            {/* 3.5 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>3.5</span> L’Utente è
              responsabile della completezza, veridicità e correttezza delle
              informazioni fornite al Titolare nel contesto della procedura di
              registrazione finalizzata alla creazione dell’Account,
              obbligandosi a comunicare prontamente al Titolare ogni modifica di
              tali dati, informazioni e documenti forniti in sede di
              registrazione o successivamente, o comunque a disposizione del
              Titolare in relazione al proprio Account.
            </Typography>

            {/* 3.6 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>3.6</span> Ad esito della
              procedura di registrazione, il Titolare invierà all’Utente
              all’indirizzo e-mail comunicato in sede di registrazione, una
              comunicazione di conferma della registrazione.
            </Typography>

            {/* 3.7 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>3.7</span> Le Credenziali di
              Accesso devono essere conservate dall’Utente utilizzando la
              massima riservatezza e diligenza, con estrema cura e attenzione,
              in modo tale da impedirne l’utilizzo indebito da parte di terzi.
              Esse possono essere utilizzate solo dall’Utente e non possono
              essere cedute a terzi. L’Utente è l’unico ed esclusivo
              responsabile della custodia delle Credenziali di Accesso e si
              impegna a mantenerle segrete e ad assicurarsi che nessun terzo vi
              abbia accesso. Egli si impegna inoltre a informare immediatamente
              il Titolare in caso di furto, smarrimento, sottrazione delle
              Credenziali di Accesso, ovvero nel caso in cui sospetti o venga a
              conoscenza di un uso indebito o di una indebita divulgazione delle
              stesse. L’Utente sarà il solo responsabile di qualsiasi danno
              arrecato a se stesso e/o a terzi in caso di accesso alle
              Credenziali di Accesso e/o indebito utilizzo delle stesse da parte
              di terzi.
            </Typography>

            {/* 3.8 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>3.8</span> Il Titolare si
              riserva di verificare di volta in volta i dati, le informazioni e
              i documenti forniti dall’Utente in fase di registrazione, di
              chiederne periodicamente l’aggiornamento e/o di richiedere la
              trasmissione di dati, informazioni o documenti aggiuntivi, anche
              in base a quanto richiesto dalla normativa applicabile. Il
              Titolare potrà, a proprio insindacabile giudizio, disporre la
              sospensione e/o l’interruzione dei Servizi offerti e/o
              dell’accesso alla Piattaforma in caso di rifiuto di collaborare da
              parte dell’Utente, ovvero laddove quest’ultimo non fornisca
              informazioni, dati o documenti completi, corretti e veritieri
              secondo i tempi richiesti dal Titolare.
            </Typography>

            {/* 4 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              4. Diritto di recesso e rimborso
            </Typography>

            {/* 4.1 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>4.1</span> 4.1 L’Utente
              rinuncia espressamente alla facoltà di esercitare il proprio
              diritto di recesso con riferimento ai Servizi offerti sulla
              Piattaforma ai sensi dell’Articolo 59, par 1, lett. o) del D. Lgs.
              206/2005.
            </Typography>

            {/* 5 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              5. Cancellazione dell’Account da parte dell’Utente
            </Typography>

            {/* 5.1 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>5.1</span> L’Utente potrà
              domandare in ogni momento la disattivazione del proprio account
              inviando una comunicazione scritta via e-mail all’indirizzo
              <a className="textLink" href="mailto:info@legalfarm.it">
                info@legalfarm.it
              </a>
              .
            </Typography>

            {/* 5.2 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>5.2</span> A seguito della
              trasmissione della richiesta di cancellazione, l’Account
              dell’Utente sarà cancellato dalla Piattaforma entro 10 (dieci)
              giorni lavorativi. In tal caso, i dati personali forniti
              dall’Utente saranno cancellati ai sensi dell’Informativa Privacy
              consultabile all’indirizzo{" "}
              <a className="textLink" href="/informativa-privacy">
                www.legalfarm.it/informativa-privacy
              </a>
              .
            </Typography>

            {/* 5.3 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>5.3</span> A seguito della
              cancellazione dell’Account, l’Utente non potrà più utilizzare i
              Servizi offerti tramite la Piattaforma nonché visualizzare lo
              storico dei Servizi acquistati tramite il proprio Account.
            </Typography>

            {/* 6 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              6. Sospensione e Cancellazione dell’Account da parte del Titolare
            </Typography>

            {/* 6.1 */}
            <Typography className="text-terms" style={{ marginBottom: "10px" }}>
              <span style={{ marginRight: "5px" }}>6.1</span> In aggiunta alle
              ipotesi espressamente contemplate dalle altre previsioni delle
              presenti Condizioni Generali, il Titolare si riserva in ogni caso
              la facoltà di sospendere, bloccare l’accesso all’Account e/o di
              cancellare lo stesso, interrompendo la prestazione dei Servizi, a
              propria discrezione, previa comunicazione scritta via e-mail:
            </Typography>
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              a) qualora l’Utente abbia fornito informazioni non veritiere
              durante la registrazione per la creazione del proprio Account
              ovvero successivamente nell’ambito del proprio rapporto con il
              Titolare;
              <br /> b) in caso di mancato utilizzo e, in ogni caso, di mancato
              accesso all’Account per un periodo di tempo superiore a 36
              (trentasei) mesi;
              <br /> c) qualora l’Utente si rifiuti di fornire informazioni
              relative al proprio Account e/o alla propria identità, se del caso
              su richieste dell’Autorità giudiziaria, delle autorità di ordine
              pubblico e di qualsiasi altra autorità che ne abbia titolo ai
              sensi delle leggi applicabili;
              <br /> d) in caso di violazione da parte dell’Utente delle
              disposizioni di cui alle presenti Condizioni Generali o di legge;
              <br /> e) in caso di utilizzo fraudolento o illecito, anche solo
              sospetto, della Piattaforma;
              <br /> f) al fine di proteggere l’Utente e/o la Piattaforma da
              furti di identità e/o altre attività fraudolente dell’Utente e/o
              di terze parti;
              <br /> g) qualora venga così richiesto dall’Autorità giudiziaria,
              dalle autorità di ordine pubblico e da qualsiasi altra autorità
              che ne abbia titolo ai sensi delle leggi applicabili.
            </Typography>

            {/* 6.2 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>6.2</span> In caso di
              sospensione, il Titolare potrà decidere, a propria discrezione e
              insindacabile giudizio, se riattivare o cancellare permanentemente
              l’Account dell’Utente. Per procedere alla riattivazione
              dell’Account sospeso, l’Utente deve farne richiesta esplicita al
              Titolare inviando apposita comunicazione come previsto ai sensi
              dell’articolo 12 che segue.
            </Typography>

            {/* 6.3 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>6.3</span> In caso di
              cancellazione dell’Account, l’Utente non potrà più utilizzare i
              Servizi offerti tramite la Piattaforma nonché visualizzare lo
              storico dei Servizi acquistati tramite il proprio Account.
            </Typography>

            {/* 7 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              7. Prezzo dei Servizi e modalità di pagamento
            </Typography>

            {/* 7.1 */}
            <Typography className="text-terms" style={{ marginBottom: "10px" }}>
              <span style={{ marginRight: "5px" }}>7.1</span> Il prezzo dovuto
              dall’Utente al Titolare per i Servizi è indicato tramite la
              Piattaforma (il “
              <span style={{ fontWeight: "bold" }}>Prezzo</span>
              ”):
            </Typography>
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              (i) all’interno di ogni pagina web descrittiva del Servizio,
              nonché al momento dell’acquisto del Servizio qualora si tratti di
              un Servizio Standard.
              <br /> (ii) nel preventivo fornito dal Professionista qualora si
              tratti di un Servizio a Preventivo.
            </Typography>

            {/* 7.2 */}
            <Typography className="text-terms" style={{ marginBottom: "10px" }}>
              <span style={{ marginRight: "5px" }}>7.2</span> Il Titolare non
              potrà essere ritenuto in alcun modo responsabile per la variazione
              degli eventuali costi successivi a carico dell’Utente,
              complementari al Prezzo di acquisto di un Servizio, per la
              corretta finalizzazione di talune pratiche. Tali costi successivi
              vengono indicati al cliente nella Piattaforma a mero scopo
              informativo. Rientrano in questa categoria, a titolo
              esemplificativo e non esaustivo:
            </Typography>
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              (i) la variazione improvvisa delle imposte di legge previste per
              uno specifico servizio;
              <br /> (ii) la variazione di parametri legali che determinano
              l’importo del compenso di professionisti del tutto esterni alla
              Piattaforma (es. notaio).
            </Typography>

            {/* 7.3 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>7.3</span> Affinché l’Utente
              possa usufruire dei Servizi, il pagamento del Prezzo dovrà essere
              effettuato tramite il servizio PayPal (Europe) S.à.r.l. et Cie,
              S.C.A. (“<span style={{ fontWeight: "bold" }}>PayPal</span>”)
              integrato nella Piattaforma. Al fine di completare il pagamento,
              l’Utente verrà reindirizzato alla pagina di login del sito PayPal
              dove inserire l’indirizzo e-mail personale e la password del
              proprio conto PayPal, oppure accedere alla procedura per la
              creazione di un nuovo conto (il “
              <span style={{ fontWeight: "bold" }}>Link di Pagamento</span>”).
              Dopo la prima registrazione è possibile procedere ai pagamenti via
              PayPal senza dover più reinserirne i dati, anche per i successivi
              acquisti. Il Prezzo dovuto per il Servizio viene addebitato sul
              conto PayPal dall’Utente. Per ogni transazione eseguita con il
              conto PayPal, l’Utente riceverà un email di conferma da PayPal. Le
              condizioni di utilizzo PayPal sono consultabili all’indirizzo
              www.paypal.com/it/webapps/mpp/ua/useragreement-full.
            </Typography>

            {/* 8 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              8. Contenuti inviati dagli Utenti
            </Typography>

            {/* 8.1 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>8.1</span> L’Utente potrà
              caricare contenuti sulla Piattaforma per le sole finalità connesse
              ai Servizi, purché tali contenuti non abbiano carattere illecito,
              ingannevole, contrario al buon costume (ad es. aventi contenuto
              osceno, intimidatorio, diffamatorio, pornografico, abusivo, ovvero
              che a qualsiasi titolo violino la privacy, i diritti di proprietà
              intellettuale e/o industriale del Titolare e/o di terzi), ovvero
              che non risultino lesivi dei diritti o della reputazione del
              Titolare e/o di terzi e non contengano virus, propaganda politica
              e sollecitazione commerciale. L’Utente si impegna a tenere
              manlevato e indenne il Titolare da qualsiasi danno, perdita o
              spesa sorta in conseguenza a qualsiasi forma di contestazione da
              parte di terzi relativamente a tali contenuti caricati sulla
              Piattaforma.
            </Typography>

            {/* 8.2 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>8.2</span> È fatto divieto
              all’Utente di utilizzare indirizzi di posta elettronica nonché
              dati personali e Credenziali di Accesso di titolarità di altri
              Utenti.
            </Typography>

            {/* 8.3 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>8.3</span> Il Titolare si
              riserva il diritto di eseguire controlli sui contenuti caricati
              dall’Utente, e dunque potrà in ogni momento cancellare, spostare,
              modificare i contenuti che, a suo insindacabile giudizio, possano
              apparire illeciti, abusivi, diffamatori, osceni o lesivi di
              qualsivoglia diritto.
            </Typography>

            {/* 8.4 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>8.4</span> L’Utente concede
              al Titolare, al fine di erogare i Servizi, un diritto d’uso non
              esclusivo sui contenuti caricati, senza limitazioni di aree
              geografiche. Entro i suddetti limiti, il Titolare potrà pertanto,
              direttamente o tramite terzi vincolati da obblighi di
              confidenzialità, utilizzare ogni contenuto inviato dall’Utente.
            </Typography>

            {/* 9 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              9. Garanzie e limitazioni di responsabilità
            </Typography>

            {/* 9.1 */}
            <Typography className="text-terms" style={{ marginBottom: "10px" }}>
              <span style={{ marginRight: "5px" }}>9.1</span> L’Utente dichiara
              e garantisce di:
            </Typography>
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              a) utilizzare la Piattaforma e/o i Servizi, in conformità con le
              previsioni delle presenti Condizioni Generali, alla legge, al buon
              costume, all’ordine pubblico. Alla stessa maniera, l’Utente si
              impegna a fare un uso appropriato dei Servizi e/o dei contenuti
              della Piattaforma, non impiegandoli per attività illecite,
              contrarie alla buona fede o al buon costume o delittuose, e a non
              violare diritti di terzi;
              <br /> b) utilizzare la Piattaforma sotto la propria esclusiva
              responsabilità, in conformità e nel rispetto delle caratteristiche
              tecniche e delle funzionalità della Piattaforma stessa nonché, se
              del caso, delle istruzioni e indicazioni di volta in volta
              impartite dal Titolare;
              <br /> c) assumersi la piena responsabilità delle informazioni e
              del materiale forniti. In particolare, l’Utente si impegna a non
              trasmettere, introdurre, distribuire e/o rendere disponibile a
              terzi qualsiasi materiale e/o informazione (a titolo
              esemplificativo: dati, messaggi, immagini, documenti, file audio,
              fotografie) che sia in contrasto con la morale, l’ordine pubblico,
              le presenti Condizioni Generali e, in generale, con ogni
              disposizione di legge e/o regolamento applicabile. È proibito
              all’Utente l’uso della Piattaforma e dei Servizi forniti per scopi
              diversi da quelli previsti nelle presenti Condizioni Generali;
              <br /> d) astenersi dall’assumere qualsiasi condotta o dal
              compiere qualsiasi operazione che causi o possa causare danni alla
              Piattaforma o che possa comprometterne le prestazioni, la
              disponibilità e/o l’accessibilità. La Piattaforma dovrà essere in
              ogni caso utilizzata in modo lecito, senza porre in essere alcuna
              attività di carattere fraudolenta nei confronti del Titolare, di
              altri Utenti e/o di soggetti terzi;
              <br /> e) utilizzare l’Account soltanto a titolo personale e non
              per conto terzi. Le Credenziali di Accesso potranno essere
              utilizzate esclusivamente dall’Utente e non saranno da
              quest’ultimo cedute o rivelate in alcun modo a soggetti terzi.
            </Typography>

            {/* 9.2 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>9.2</span> L’Utente prende
              atto ed accetta che il Titolare avrà il diritto di sospendere o
              interrompere l’utilizzo della Piattaforma e dei Servizi e/o
              procedere alla cancellazione dell’Account in qualsiasi momento in
              caso di mancato rispetto degli obblighi sopra elencati da parte
              dell’Utente. L’Utente si farà inoltre carico, in via esclusiva, di
              qualsivoglia perdita o danno che dovesse derivare
              dall’inadempimento di detti obblighi e si impegna a tenere il
              Titolare manlevato e indenne in caso di eventuali azioni, pretese,
              costi, perdite o altre conseguenze sfavorevoli derivanti da tale
              inadempimento.
            </Typography>

            {/* 9.3 */}
            <Typography className="text-terms" style={{ marginBottom: "10px" }}>
              <span style={{ marginRight: "5px" }}>9.3</span> L’Utente riconosce
              e accetta espressamente che:
            </Typography>
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              a) il Titolare non fornisce alcuna garanzia in merito alla qualità
              delle funzionalità della Piattaforma e/o dei Servizi. In
              particolare, la Società non garantisce che la Piattaforma e/o i
              Servizi soddisfino le necessità dell’Utente e/o siano disponibili
              in modo ininterrotto, sicuro e privo di errori;
              <br /> b) in nessun caso il Titolare e/o i Professionisti di cui
              si avvale per l’erogazione dei Servizi saranno responsabili nei
              confronti dell’Utente o di terzi con riferimento a qualsiasi
              illecito, negligenza, responsabilità oggettiva in relazione a
              qualsiasi perdita di profitto, dati persi o danneggiati, guasti o
              malfunzionamenti del computer, interruzione dell’attività, o altri
              danni speciali, indiretti, incidentali o consequenziali di
              qualsiasi tipo derivanti dall’uso o dall’impossibilità di
              utilizzare la Piattaforma e/o i Servizi, anche qualora l’Utente
              sia stato avvisato della possibilità di tali perdite o danni e se
              tali perdite o danni erano prevedibili; <br /> c) in nessun caso
              il Titolare è responsabile e potrà essere ritenuto responsabile
              del corretto funzionamento del Link di Pagamento e/o di qualsiasi
              danno derivante dall’utilizzo da parte dell’Utente di PayPal e/o
              altro servizio di pagamento in caso di malfunzionamento degli
              stessi per causa di terze parti; <br /> d) il Titolare non avrà
              alcuna responsabilità per qualsiasi uso illegittimo della
              Piattaforma e/o dei Servizi da parte dell’Utente. Quest’ultimo
              dovrà manlevare e tenere indenne la Società da qualsiasi reclamo,
              danno, responsabilità, costo e spesa (incluse le ragionevoli spese
              legali) derivanti dall’utilizzo della Piattaforma e/o dei Servizi
              da parte dell’Utente in violazione delle previsioni delle presenti
              Condizioni Generali; <br /> e) il Titolare potrà modificare,
              limitare o rimuovere tutte o alcune funzionalità della Piattaforma
              e/o i Servizi per motivi di sicurezza dei dati, necessità tecniche
              o a causa di modifiche della legge applicabile, a condizione che
              la modifica, la limitazione o la rimozione sia ragionevole per
              l’Utente in considerazione degli interessi del Titolare e
              dell’Utente; <br /> f) il Titolare non fornisce alcuna garanzia
              circa la disponibilità e la velocità dei propri Servizi, compresa
              la Piattaforma, l’evasione delle richieste impartite dall’Utente e
              la disponibilità delle diverse funzionalità fornite tramite la
              Piattaforma, anche in considerazione del fatto che i tempi tecnici
              per l’evasione completa dei Servizi possono dipendere dalla
              rapidità di enti, professionisti e soggetti esterni
              necessariamente coinvolti; <br /> g) il Titolare non sarà in
              nessun caso responsabile del malfunzionamento della Piattaforma o
              dei Servizi offerti, qualora ciò dipenda da cause impreviste ed
              imprevedibili, di forza maggiore o dipendenti da condotte di
              soggetti terzi. Il Titolare sarà esente da qualsiasi
              responsabilità per i vizi originari o sopravvenuti della
              Piattaforma.
            </Typography>

            {/* 10 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              10. Obblighi di riservatezza
            </Typography>

            {/* 10.1 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>10.1</span> Tutte le
              informazioni scambiate tra l’Utente e il Titolare nonché gli
              eventuali Professionisti coinvolti ai sensi delle presenti
              Condizioni Generali saranno considerate strettamente confidenziali
              (le “
              <span style={{ fontWeight: "bold" }}>Informazioni Riservate</span>
              ”) e saranno utilizzate esclusivamente per la fornitura dei
              Servizi.
            </Typography>

            {/* 10.2 */}
            <Typography className="text-terms" style={{ marginBottom: "10px" }}>
              <span style={{ marginRight: "5px" }}>10.2</span> Il Titolare si
              impegna a:
            </Typography>
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              a) divulgare le Informazioni Riservate solo ai suoi rappresentanti
              che abbiano necessità di conoscerle, a condizione che tali
              rappresentanti siano vincolati da obblighi di non divulgazione;
              <br /> b) proteggere le Informazioni Riservate dalla divulgazione
              nello stesso modo e con lo stesso dovere di diligenza che l’Utente
              utilizza per proteggere le proprie Informazioni Riservate di pari
              importanza; <br /> c) distruggere le Informazioni Riservate su
              richiesta dell’Utente, ad eccezione di qualsiasi parte che debba
              essere conservata per legge.
            </Typography>

            {/* 10.3 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>10.3</span> Nel caso in cui
              una parte sia tenuta a divulgare una qualsiasi delle Informazioni
              Riservate ai sensi di legge, se consentito dalla legge
              applicabile, dovrà prontamente notificare all’altra parte i
              termini e le circostanze di tale richiesta.
            </Typography>

            {/* 11 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              11. Diritti di proprietà industriale e intellettuale
            </Typography>

            {/* 11.1 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>11.1</span> Il Titolare
              dichiara di essere titolare di tutti i diritti di proprietà
              intellettuale e industriale relativi e/o afferenti alla
              Piattaforma nonché ai contenuti ai quali è possibile accedere
              tramite la medesima. Pertanto, tutti i marchi, figurativi o
              nominativi e tutti gli altri segni, nomi commerciali, marchi di
              servizio, marchi denominativi, denominazioni commerciali,
              illustrazioni, immagini, loghi, contenuti, le immagini presenti
              sulla Piattaforma, nonché il design grafico e la struttura di
              navigazione della stessa sono e rimangono di titolarità del
              Titolare, che detiene il diritto esclusivo di esercitare i diritti
              di sfruttamento in qualsiasi modo, in particolare i diritti di
              riproduzione, distribuzione, pubblicazione e trasformazione, in
              conformità con la legge italiana in materia di diritti di
              proprietà intellettuale e industriale.
            </Typography>

            {/* 11.2 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>11.2</span> Le presenti
              Condizioni Generali non concedono all’Utente alcuna licenza d’uso
              relativa alla Piattaforma e/o a singoli contenuti ivi disponibili,
              se non diversamente disciplinato.
            </Typography>

            {/* 11.3 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>11.3</span> L’autorizzazione
              ad accedere alla Piattaforma garantita dal Titolare all’Utente
              dalla data di attivazione dell’Account, non può essere
              interpretata quale rinuncia, cessione, concessione di licenza o in
              ogni caso assegnazione anche parziale da parte del Titolare
              all’Utente di qualsiasi diritto di proprietà intellettuale o
              industriale.
            </Typography>

            {/* 11.4 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>11.4</span> All’Utente è
              vietato modificare, copiare, riutilizzare, sfruttare, riprodurre,
              pubblicizzare, inviare per posta, trasmettere, l’elaborare o
              distribuire in qualsivoglia modo di tutti o di parte dei contenuti
              della Piattaforma anche a fini pubblicitari e/o commerciali.
              Eventuali riproduzioni dei contenuti della Piattaforma saranno
              considerate violazioni del diritto proprietà intellettuale ed
              industriale del Titolare.
            </Typography>

            {/* 12 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              12. Comunicazioni
            </Typography>

            {/* 12.1 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>12.1</span> Per qualsiasi
              comunicazione, contestazione, richiesta o reclamo relativo alla
              Piattaforma e/o ai Servizi prestati dal Titolare, l’Utente può
              utilizzare l’indirizzo e-mail:{" "}
              <a className="textLink" href="mailto:info@legalfarm.it">
                info@legalfarm.it
              </a>
              .
            </Typography>

            {/* 13 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              13. Legge applicabile e foro competente
            </Typography>

            {/* 13.1 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>13.1</span> Le presenti
              Condizioni Generali sono disciplinate e dovranno essere
              interpretate in base alla legge italiana.
            </Typography>

            {/* 13.2 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>13.2</span> Le eventuali
              controversie relative alle Condizioni Generali saranno devolute
              alla giurisdizione esclusiva del foro di Milano.
            </Typography>

            {/* 14 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              14. Rinvio a norme di legge
            </Typography>

            {/* 14.1 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>14.1</span> Per quanto non
              previsto dalle presenti Condizioni Generali, saranno applicabili
              le norme di legge italiana vigenti.
            </Typography>

            {/* 15 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              15. Rinvio a norme di legge
            </Typography>

            {/* 15.1 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>15.1</span> Il Titolare si
              impegna ad applicare misure tecniche ed organizzative adeguate a
              garantire l’adempimento puntuale ed esatto di tutti gli obblighi
              vigenti in materia di privacy e trattamento dei dati personali,
              secondo quanto prescritto dal Regolamento UE 2016/679 sulla
              protezione dei dati (“
              <span style={{ fontWeight: "bold" }}>GDPR</span>”), nonché da ogni
              altra normativa anche nazionale applicabile.
            </Typography>

            {/* 15.2 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>15.2</span> Il Titolare si
              configura quale titolare del trattamento ai sensi dell’Articolo 24
              del GDPR in relazione al trattamento dei dati personali
              dell’Utente; tale trattamento avverrà in conformità a quanto
              previsto nell’informativa Privacy consultabile all’indirizzo
              <a className="textLink" href="/informativa-privacy">
                www.legalfarm.it/informativa-privacy
              </a>
              .
            </Typography>

            {/* 16 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              16. Modifica delle Condizioni Generali
            </Typography>

            {/* 16.1 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>16.1</span> Il Titolare si
              riserva il diritto di modificare unilateralmente le presenti
              Condizioni Generali in qualsiasi momento, anche al fine di
              adattarle a modifiche legislative o a eventuali modifiche ai
              Servizi stessi.
            </Typography>

            {/* 16.2 */}
            <Typography className="text-terms" style={{ marginBottom: "20px" }}>
              <span style={{ marginRight: "5px" }}>16.2</span>Il Titolare si
              riserva il diritto di variare a propria discrezione, in qualunque
              momento anche successivo alla registrazione dell’Utente,
              l’interfaccia grafica dalla Piattaforma, i contenuti e la loro
              organizzazione nonché ogni altro aspetto che caratterizza la
              funzionalità e la gestione della Piattaforma, comunicando
              all’Utente, ove occorrenti, le relative istruzioni.
            </Typography>

            {/* 16.2 */}
            <Typography
              className="text-terms"
              style={{ marginBottom: "20px", fontStyle: "italic" }}
            >
              Ultimo aggiornamento: 16/05/2023
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
