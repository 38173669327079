import React, { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../../assets/css/Generics/FaqGlobal.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface TrademarkFaqProps {
  PageFaq?: boolean;
}

const TrademarkFaq: React.FC<TrademarkFaqProps> = ({ PageFaq }) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Cos'è il marchio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Il marchio è il simbolo grafico o testuale che identifica i prodotti
            o i servizi di un’impresa per distinguerli da quelli di altre
            aziende.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            A cosa serve la registrazione del marchio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            La registrazione è l’operazione con cui il titolare di un marchio
            ottiene l’utilizzo esclusivo dello stesso. Una volta effettuata la
            registrazione, i terzi non possono registrare un marchio identico o
            utilizzare il marchio già registrato senza il consenso del suo
            titolare. Inoltre, questa operazione è fondamentale per conferire al
            marchio un valore commerciale. Dal momento della registrazione,
            infatti, il titolare può concedere l’utilizzo del marchio a terzi
            dietro pagamento (es. licenza d’uso).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Cosa sono le classi merceologiche?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Le classi merceologiche sono delle categorie di prodotti e servizi
            riconosciute a livello internazionale (Classificazione di Nizza).
            Quando si registra un marchio bisogna indicare per quale classe si
            richiede il deposito a seconda del prodotto o servizio offerto (es.
            veicoli, mobili, servizi giuridici o informatici). In alcuni casi,
            un prodotto o servizio può appartenere contemporaneamente a più
            classi e quindi il titolare del marchio ha interesse a registrarlo
            in ogni classe utile. In questo modo si estende il raggio
            dell’utilizzo esclusivo.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Che differenza c'è tra marchio italiano e marchio europeo
            (comunitario)?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Quando il marchio è registrato solo sul territorio nazionale, il
            titolare ha il diritto di utilizzo esclusivo unicamente in Italia.
            Ad esempio, se registro solo in Italia il marchio “Alfa”, un terzo
            potrebbe registrare un marchio identico in un altro Paese
            dell’Unione Europea. Invece, la registrazione a livello europeo
            garantisce al titolare l’utilizzo esclusivo del proprio marchio su
            tutto il territorio comunitario.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Cosa mi serve per completare la pratica?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Sono sufficienti la copia del tuo documento d’identità, una
            descrizione del marchio e alcuni file con il logo e i colori dello
            stesso. Non preoccuparti, ti guideremo passo per passo sui documenti
            da caricare. Se intendi registrare il marchio a nome di una società,
            servirà anche una visura aggiornata di quest’ultima.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Quali sono le imposte per la registrazione di un marchio italiano?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Per la registrazione del marchio italiano le imposte ammontano a
            €199 se il marchio viene registrato per una singola classe
            merceologica. Per le eventuali classi successive alla prima sono
            dovuti ulteriori €34 per ogni classe aggiuntiva. Non preoccuparti,
            il nostro sistema calcola in automatico i costi per il tuo marchio
            in base ai dati che inserirai.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Quali sono le imposte per la registrazione di un marchio europeo?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Per la registrazione del marchio a livello comunitario le imposte
            ammontano a €850 se il marchio viene registrato per una singola
            classe merceologica. Per la seconda eventuale classe sono dovuti
            ulteriori €50. Per le eventuali classi successive alla seconda sono
            dovuti ulteriori €150 per ogni classe aggiuntiva. Non preoccuparti,
            il nostro sistema calcola in automatico i costi per il tuo marchio
            in base ai dati che inserirai.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            La registrazione è interamente online?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Sì! Ti basta comunicarci i dati, non dovrai recarti in nessun
            ufficio.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Cosa è incluso nel servizio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
            style={{ marginBottom: "10px" }}
          >
            Il nostro servizio include l’intera gestione della pratica con
            l’assistenza di un professionista sempre a disposizione.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Posso registrare il marchio in più classi merceologiche?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Certamente! Puoi scegliere di registrare il marchio in più classi.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Quali sono le tempistiche per ottenere il certificato di deposito?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            L’invio della pratica da parte nostra è immediato non appena ci
            fornisci tutti i dati necessari. Bisogna poi attendere i tempi
            tecnici degli enti competenti a evadere la pratica. In media, sono
            necessari dai 10 ai 30 giorni. Purtroppo, queste tempistiche non
            dipendono da noi ma sollecitiamo regolarmente gli enti in caso di
            lunghe attese.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Per quanti anni è valida la registrazione?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            La registrazione ha validità 10 anni. Al termine del periodo potrai
            rinnovare il deposito per ulteriori 10 anni e così via ad ogni
            scadenza.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Come posso pagare?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Puoi acquistare il servizio pagando tramite PayPal (conto corrente
            associato o semplice carta di credito abilitata agli acquisti
            online).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={
            <ExpandMoreIcon
              className={
                PageFaq === true ? "color-primary" : "color-orange-dark"
              }
            />
          }
        >
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-20"
                : "color-orange-dark text-20"
            }
            style={{ fontWeight: "bold" }}
          >
            Il servizio prevede un abbonamento?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className={
              PageFaq === true
                ? "color-primary text-accordion-faq"
                : "color-orange-dark text-accordion-faq"
            }
          >
            Assolutamente no! Paghi soltanto la singola pratica di
            registrazione.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default TrademarkFaq;
