import React from "react";
import "../../src/assets/css/Privacy.css";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";

const Privacy: React.FC = () => {
  return (
    <>
      <Navbar />
      <div>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Grid item xs={9}>
            {/* title  */}
            <Typography className="text-title" style={{ marginBottom: "30px" }}>
              Informativa privacy (Privacy policy)
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "30px" }}
            >
              Costituzione Srl società a responsabilità limitata, in qualità di
              titolare del trattamento, informa ai sensi dell’Articolo 13
              Regolamento UE n. 2016/679 (“
              <span style={{ fontWeight: "bold" }}>GDPR</span>”) che i dati
              forniti dagli utenti (l’“
              <span style={{ fontWeight: "bold" }}>Interessato</span>” o l’“
              <span style={{ fontWeight: "bold" }}>Utente</span>
              ”) tramite il sito www.legalfarm.it (il “
              <span style={{ fontWeight: "bold" }}>Sito</span>”),
              indipendentemente dalle modalità e dallo strumento utilizzato,
              saranno trattati con le modalità e per le finalità seguenti.
            </Typography>

            {/* 1 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              1. Titolare del trattamento dei dati personali
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              Titolare del trattamento è Costituzione Srl società a
              responsabilità limitata, con sede legale in Milano (MI), Via del
              Lauro 9, codice fiscale e partita IVA n. 10353830960 (di seguito,
              il “
              <span style={{ fontWeight: "bold" }}>
                Titolare del Trattamento
              </span>
              ”).
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              Il Titolare del Trattamento mette a disposizione il seguente
              indirizzo di posta elettronica per ogni comunicazione:
              <a className="textLink" href="mailto:privacy@legalfarm.it">
                privacy@legalfarm.it.
              </a>
              .
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              Il Titolare del Trattamento potrà designare uno o più responsabili
              del trattamento dei Dati Personali ai sensi dell’articolo 28 del
              GDPR, che, per conto del Titolare del Trattamento, forniscono
              specifici servizi elaborativi o attività connesse, strumentali o
              di supporto adottando tutte quelle misure tecniche e organizzative
              adeguate a tutelare i diritti, le libertà e i legittimi interessi
              che sono riconosciuti per legge agli Interessati.
            </Typography>

            {/* 2 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              2. Descrizione del trattamento
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              Il trattamento avrà ad oggetto singole operazioni, o un complesso
              di operazioni, dei seguenti dati personali forniti
              dall’Interessato in occasione della fruizione dei servizi resi dal
              Titolare del Trattamento, tramite il Sito, come descritto nella
              seguente tabella (i “
              <span style={{ fontWeight: "bold" }}>Dati Personali</span>” o i “
              <span style={{ fontWeight: "bold" }}>Dati</span>”):
            </Typography>

            <TableContainer component={Paper} style={{ marginTop: "40px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tipologia</TableCell>
                    <TableCell>Finalità del Trattamento</TableCell>
                    <TableCell>Base Giuridica</TableCell>
                    <TableCell>Periodo di Conservazione</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Prima riga */}
                  <TableRow>
                    {" "}
                    <TableCell rowSpan={2} style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        <span style={{ fontWeight: "bold" }}>
                          Dati di Identificazione forniti per registrarsi al
                          Sito
                        </span>
                        : <br /> nome, cognome, email.
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <ul>
                        <li>
                          <Typography className="text-privacy">
                            {" "}
                            Processare e gestire una richiesta di registrazione
                            al Sito;
                          </Typography>
                        </li>
                        <li>
                          <Typography className="text-privacy">
                            creare un account all’Utente.
                          </Typography>
                        </li>
                      </ul>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        {" "}
                        Esecuzione di un contratto di cui l’Interessato è parte
                        o esecuzione di misure precontrattuali adottate su
                        richiesta dello stesso (Articolo 6 paragrafo 1 lettera
                        b) del GDPR).
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        {" "}
                        Per tutta la durata del rapporto contrattuale e fino
                        alla cancellazione dell’account. In ogni caso, i Dati di
                        identificazione non potranno essere conservati per un
                        periodo superiore a dieci (10) anni dalla data di
                        registrazione al Sito.
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Seconda riga */}
                  <TableRow>
                    {" "}
                    <TableCell style={{ verticalAlign: "top" }}>
                      <ul>
                        <li>
                          <Typography className="text-privacy">
                            {" "}
                            Inviare comunicazioni connesse all’attività con
                            riferimento alla quale l’Interessato ha fornito i
                            propri Dati;
                          </Typography>
                        </li>
                        <li>
                          <Typography className="text-privacy">
                            inviare periodicamente inviti a completare
                            un’attivazione contrattuale precedentemente
                          </Typography>
                        </li>
                        <li>
                          <Typography className="text-privacy">
                            gestire e mantenere il Sito.
                          </Typography>
                        </li>
                      </ul>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        Legittimo interesse del Titolare del Trattamento
                        (Articolo 6 paragrafo 1 lettera f) del GDPR).
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        Per la durata del rapporto contrattuale e sino alla
                        cancellazione dell’account.
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Terza riga  */}
                  <TableRow>
                    {" "}
                    <TableCell style={{ width: "27%" }}>
                      <Typography className="text-privacy">
                        <span style={{ fontWeight: "bold" }}>
                          Dati forniti dall’Interessato per usufruire di uno o
                          più servizi (ove applicabili), tra cui
                        </span>
                        :
                        <br />
                        <br />
                        Costituzione di una società, con riferimento al socio
                        persona fisica o, in caso di socio persona giuridica,
                        con riferimento ai legali rappresentanti e
                        amministratori: nome, cognome, codice fiscale, sesso,
                        data e luogo di nascita, cittadinanza, indirizzo di
                        residenza, indirizzo di domicilio se diverso da quello
                        di residenza, telefono cellulare, indirizzo e-mail.
                        <br />
                        <br />
                        Costituzione di una start-up innovativa: nome, cognome,
                        codice fiscale, sesso, data e luogo di nascita,
                        cittadinanza, indirizzo di residenza, indirizzo di
                        domicilio se diverso da quello di residenza, telefono
                        cellulare, indirizzo e-mail, dati personali contenuti
                        nel Curriculum di soci, dipendenti e collaboratori.
                        <br />
                        <br />
                        Compravendita di quote, con riferimento a soci, cedenti
                        e cessionari di quote: nome, cognome, codice fiscale,
                        sesso, data e luogo di nascita, cittadinanza, indirizzo
                        di residenza, indirizzo di domicilio se diverso da
                        quello di residenza, telefono cellulare, indirizzo
                        e-mail.
                        <br />
                        <br />
                        Servizio di attivazione firma digitale con riferimento
                        ai richiedenti: nome, cognome, codice fiscale, sesso,
                        data e luogo di nascita, cittadinanza, indirizzo di
                        residenza, indirizzo di domicilio se diverso da quello
                        di residenza, telefono cellulare, indirizzo e-mail.
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <ul>
                        <li>
                          <Typography className="text-privacy">
                            Erogare il servizio richiesto dall’Utente.
                          </Typography>
                        </li>
                      </ul>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        Esecuzione di un contratto di cui l’Interessato è parte
                        o esecuzione di misure precontrattuali adottate su
                        richiesta dello stesso (Articolo 6 paragrafo 1 lettera
                        b) del GDPR).
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        Per tutta la durata del rapporto contrattuale e sino
                        alla cancellazione dell’account. In ogni caso, i Dati di
                        identificazione non potranno essere conservati per un
                        periodo superiore a dieci (10) anni dalla data di
                        registrazione al Sito.
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Quarta riga  */}
                  <TableRow>
                    {" "}
                    <TableCell style={{ verticalAlign: "top", width: "27%" }}>
                      <Typography className="text-privacy">
                        <span style={{ fontWeight: "bold" }}>
                          Dati forniti dall’Interessato nel form di contatto
                        </span>
                        : <br /> nome, cognome, email.
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <ul>
                        <li>
                          <Typography className="text-privacy">
                            Rispondere alle richieste degli Interessati, i quali
                            potranno essere ricontattati tramite e-mail o per
                            mezzo di altri sistemi di comunicazione, se forniti
                            dai medesimi.
                          </Typography>
                        </li>
                      </ul>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        Esecuzione di un contratto di cui l’Interessato è parte
                        o esecuzione di misure precontrattuali adottate su
                        richiesta dello stesso (Articolo 6 paragrafo 1 lettera
                        b) del GDPR).
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        Per il tempo necessario a soddisfare le richieste degli
                        Interessati ovvero per l’esecuzione dei servizi. In ogni
                        caso, questi dati non potranno essere conservati per un
                        periodo superiore a dieci (10) anni dal soddisfacimento
                        delle richieste pervenute dall’Interessato.
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Quinta riga  */}
                  <TableRow>
                    {" "}
                    <TableCell style={{ verticalAlign: "top", width: "27%" }}>
                      <Typography className="text-privacy">
                        <span style={{ fontWeight: "bold" }}>
                          Dati forniti spontaneamente dall’Interessato nel form
                          di contatto
                        </span>{" "}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <ul>
                        <li>
                          <Typography className="text-privacy">
                            Rispondere alle richieste degli Interessati, i quali
                            potranno essere ricontattati tramite e-mail o per
                            mezzo di altri sistemi di comunicazione, se forniti
                            dai medesimi.
                          </Typography>
                        </li>
                      </ul>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        Esecuzione di un contratto di cui l’Interessato è parte
                        o esecuzione di misure precontrattuali adottate su
                        richiesta dello stesso (Articolo 6 paragrafo 1 lettera
                        b) del GDPR).
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        Per il tempo necessario a soddisfare le richieste degli
                        Interessati ovvero per l’esecuzione dei servizi. In ogni
                        caso, questi dati non potranno essere conservati per un
                        periodo superiore a dieci (10) anni dal soddisfacimento
                        delle richieste pervenute dall’Interessato.
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Sesta riga  */}
                  <TableRow>
                    {" "}
                    <TableCell style={{ verticalAlign: "top", width: "27%" }}>
                      <Typography className="text-privacy">
                        <span style={{ fontWeight: "bold" }}>
                          Dati di navigazione:
                        </span>{" "}
                        <br /> <br />
                        <ul>
                          <li>
                            <Typography className="text-privacy">
                              indirizzi IP, indirizzi in notazione URI/URL
                              (Uniform Resource Identifier/Locator) delle
                              risorse richieste, l’orario della richiesta, il
                              metodo utilizzato nel sottoporre la richiesta al
                              server, la dimensione del file ottenuto in
                              risposta, il codice numerico indicante lo stato
                              della risposta data dal server (buon fine, errore,
                              ecc.);
                            </Typography>
                          </li>
                          <li>
                            <Typography className="text-privacy">
                              altri parametri relativi al sistema operativo e
                              all’ambiente informatico utilizzato
                              dall’Interessato.
                            </Typography>
                          </li>
                        </ul>
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <ul>
                        <li>
                          <Typography className="text-privacy">
                            Ricavare informazioni statistiche anonime sull’uso
                            del Sito e per controllarne il corretto
                            funzionamento.
                          </Typography>
                        </li>
                      </ul>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        Legittimo interesse del Titolare del Trattamento
                        (Articolo 6 paragrafo 1 lettera f) del GDPR).
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        I dati di navigazione saranno conservati per il tempo
                        necessario allo svolgimento delle attività di analisi e
                        di elaborazioni statistiche comparative, non superiore a
                        trenta (30) giorni salve eventuali necessità di
                        accertamento da parte delle autorità competenti.
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Settima riga  */}
                  <TableRow>
                    {" "}
                    <TableCell
                      style={{
                        verticalAlign: "top",
                        width: "27%",
                        paddingBottom: "20px",
                      }}
                    >
                      <Typography className="text-privacy">
                        <span style={{ fontWeight: "bold" }}>
                          Cookie e altre tecnologie di lettura/archiviazione di
                          informazioni sul terminale dell’Interessato
                        </span>{" "}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        Si rimanda alla “Cookie Policy”, disponibile al seguente
                        link:{" "}
                        <a href="/cookie" className="textLink">
                          www.legalfarm.it/cookie
                        </a>
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        Si rimanda alla “Cookie Policy”, disponibile al seguente
                        link:
                        <a href="/cookie" className="textLink">
                          www.legalfarm.it/cookie
                        </a>
                      </Typography>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Typography className="text-privacy">
                        Si rimanda alla “Cookie Policy”, disponibile al seguente
                        link:
                        <a href="/cookie" className="textLink">
                          www.legalfarm.it/cookie
                        </a>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "30px", marginTop: "40px" }}
            >
              Si precisa che, con riferimento ai dati di navigazioni, le
              informazioni raccolte, pur non essendo destinate ad essere
              associate a soggetti identificati, per loro natura, se associate
              ad altri Dati detenuti da terzi (es. internet service provider),
              potrebbero permettere l’identificazione degli Interessati (es.,
              indirizzi IP, nomi a dominio dei pc utilizzati, indirizzi URL
              delle risorse richieste, orario della richiesta, codice numerico
              relativo allo stato della risposta data dal server).
            </Typography>

            {/* 3 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              3. Modalità del trattamento
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              Il trattamento dei Dati Personali:
              <br />
              <br />
              1. è realizzato per mezzo delle operazioni indicate all’Articolo
              4, co. 1, n. 2 del GDPR e precisamente: raccolta, registrazione,
              organizzazione, conservazione, consultazione, elaborazione,
              modificazione, selezione, estrazione, raffronto, utilizzo,
              interconnessione, blocco, comunicazione, cancellazione e
              distruzione dei Dati; <br />
              2. è effettuato anche con l’ausilio di mezzi elettronici o
              comunque automatizzati; <br />
              3. è svolto anche mediante l’utilizzo di posta elettronica o di
              altre tecniche di comunicazione a distanza.
            </Typography>

            {/* 4 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              4. Trasferimento dei Dati Personali
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              La gestione e la conservazione dei Dati avverranno
              prioritariamente in Europa, su server di società terze incaricate
              e debitamente nominate quali responsabili del trattamento. <br />
              <br />
              Il Titolare del Trattamento può fornire l’accesso al Sito e ai
              servizi ivi indicati anche in altri Paesi, nel qual caso il
              trasferimento dei Dati in tali Paesi è strettamente limitato
              all’effettiva necessità di esserne a conoscenza. Il Titolare del
              Trattamento adotterà le misure necessarie per proteggere i Dati
              Personali degli Utenti e impedire l’accesso non autorizzato.{" "}
              <br /> <br />
              Nelle ipotesi in cui i Dati Personali siano trasferiti nei sistemi
              usati dal Titolare del Trattamento e/o di società terze incaricate
              e debitamente nominate quali Responsabili del trattamento anche al
              di fuori dell’Unione Europea, il Titolare del Trattamento
              garantisce l’applicazione delle clausole contrattuali standard
              della Commissione Europea per garantire un trasferimento
              internazionale sicuro dei dati personali, in base agli artt. 44,
              45 e 46 del GDPR.
            </Typography>

            {/* 5 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              5. Misure di sicurezza
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              Il Titolare del Trattamento ha adottato una varietà di misure di
              sicurezza per proteggere i Dati contro il rischio di perdita,
              abuso o alterazione, coerentemente alle misure espresse
              nell’Articolo 32 del GDPR.
            </Typography>

            {/* 6 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              6. Misure di sicurezza
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              Ferma la facoltà dell’Interessato di fornire i Dati Personali al
              Titolare del Trattamento, il conferimento dei Dati Personali può
              essere: <br /> <br />
              1. obbligatorio ai fini dell’erogazione dei servizi accessibili
              tramite il Sito e per finalità connesse all’adempimento di
              obblighi previsti dalle leggi e/o regolamenti applicabili, nonché
              da disposizioni impartite dalle competenti autorità/organi di
              vigilanza e/o controllo; <br /> 2. facoltativo con riferimento ai
              dati forniti spontaneamente dell’Interessato. <br />
              <br />
              L’eventuale rifiuto dell’Interessato di fornire i Dati Personali
              al Titolare del Trattamento, potrebbe comportare l’impossibilità
              per il Titolare del Trattamento di fornire i servizi richiesti e
              rendere disponibile l’accesso al Sito. <br /> <br /> Inoltre, si
              prega di considerare che la revoca di uno o più permessi e/o
              consensi non conferiti dall’Utente può comportare delle
              conseguenze sul corretto funzionamento e/o sulla possibilità di
              accedere e/o utilizzare correttamente il Sito e/o erogare i
              servizi da parte del Titolare del Trattamento.
            </Typography>

            {/* 7 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              7. Conservazione e cancellazione dei Dati
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              Il periodo di conservazione dei Dati Personali è indicato nella
              tabella al precedente punto 2. <br /> <br />
              Al termine del periodo di conservazione i Dati Personali saranno
              cancellati. Pertanto, allo spirare di tale termine il diritto di
              accesso, cancellazione, rettificazione ed il diritto alla
              portabilità dei Dati Personali non potranno più essere esercitati
              dall’Utente. <br />
              <br />I Dati Personali saranno conservati a mezzo di archivi
              cartacei e informatici, inclusi dispositivi portatili, adottando
              misure idonee a garantirne la sicurezza e a limitarne l’accesso
              esclusivamente al personale autorizzato dal Titolare del
              Trattamento e nello stretto ambito delle finalità sopra indicate.
            </Typography>

            {/* 8 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              8. Diritti dell’Interessato
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              L’Interessato può esercitare i diritti previsti dal Capo III del
              GDPR nei limiti ed alle condizioni ivi previste: <br /> <br />
              1.{" "}
              <span style={{ fontWeight: "bold" }}>
                accesso ai Dati (art. 15)
              </span>
              : l’Interessato ha il diritto di ottenere dal Titolare del
              Trattamento la conferma che sia o meno in corso un trattamento di
              Dati Personali che lo riguardano e, in tal caso, ottenere
              l’accesso ai Dati Personali in un formato elettronico di uso
              comune ed alcune informazioni sul trattamento (es. finalità,
              categorie di Dati trattati, destinatari, trasferimenti extra UE,
              attuazione di attività di profilazione, ecc.); <br />
              2.{" "}
              <span style={{ fontWeight: "bold" }}>
                rettifica dei Dati (art. 16)
              </span>
              : l’Interessato ha il diritto di ottenere la rettifica dei Dati
              Personali inesatti che lo riguardano senza ingiustificato ritardo
              e/o l’integrazione dei Dati Personali incompleti, anche fornendo
              una dichiarazione integrativa; <br />
              3.{" "}
              <span style={{ fontWeight: "bold" }}>
                cancellazione dei Dati o “diritto all’oblio” (art. 17)
              </span>
              : l’Interessato ha il diritto di ottenere dal Titolare del
              Trattamento la cancellazione dei Dati Personali che lo riguardano
              senza ingiustificato ritardo e il Titolare del Trattamento ha
              l’obbligo di cancellare senza ingiustificato ritardo i Dati
              Personali; <br />
              4.{" "}
              <span style={{ fontWeight: "bold" }}>
                limitazione del trattamento (art. 18)
              </span>
              : l’Interessato ha il diritto di ottenere dal Titolare del
              Trattamento la limitazione del trattamento; portabilità dei Dati
              (art. 20): l’Interessato ha il diritto di ricevere in un formato
              strutturato, di uso comune e leggibile da dispositivo automatico i
              Dati Personali che lo riguardano forniti ad un Titolare del
              Trattamento e ha il diritto di trasmettere tali Dati a un altro
              Titolare del Trattamento senza impedimenti da parte del Titolare
              del Trattamento cui li ha forniti; <br />
              5.{" "}
              <span style={{ fontWeight: "bold" }}>
                opposizione al trattamento (art. 21)
              </span>
              : l’Interessato ha il diritto di opporsi in qualsiasi momento, per
              motivi connessi alla sua situazione particolare, al trattamento
              dei Dati Personali che lo riguardano ai sensi dell’articolo 6,
              paragrafo 1, lettere e) o f) del GDPR, compresa la profilazione
              sulla base di tali disposizioni.
            </Typography>

            {/* 9 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              9. Modalità di esercizio dei diritti
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              L’Interessato potrà in qualsiasi momento esercitare i diritti
              inviando: <br /> <br />
              1. una e-mail all’indirizzo{" "}
              <a className="textLink" href="mailto:privacy@legalfarm.it">
                privacy@legalfarm.it
              </a>
              ; <br />
              2. una raccomandata A/R a LegalFarm, con sede legale in 20121
              Milano – Via del Lauro, 9. <br /> <br />
              Il Titolare del Trattamento si impegna a fornire all’Interessato
              le informazioni relative all’azione intrapresa riguardo a una
              richiesta di esercizio dei diritti senza ingiustificato ritardo e,
              comunque, al più tardi entro un termine di 30 (trenta) giorni dal
              ricevimento della richiesta stessa, estendibile fino a 3 mesi solo
              in casi di particolare complessità. <br /> <br />
              Le eventuali rettifiche o cancellazioni o limitazioni del
              trattamento effettuate su esplicita richiesta dell’Interessato
              (salvo ove ciò si riveli impossibile o implichi uno sforzo
              sproporzionato) saranno comunicate dal Titolare del Trattamento a
              ciascuno dei destinatari cui sono stati trasmessi i Dati
              Personali. Il Titolare del Trattamento potrà comunicare
              all’Interessato i riferimenti dei destinatari, qualora richiesto.
            </Typography>

            {/* 10 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              10. Diritto di reclamo
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              Gli Interessati che ritengono che il trattamento dei Dati
              Personali avvenga in violazione di quanto previsto dal GDPR hanno
              il diritto di proporre reclamo all’Autorità Garante per la
              Protezione dei Dati Personali (Garante Privacy) via e-mail,
              all’indirizzo garante@gpdp.it oppure urp@gpdp.it, via fax
              06.696773785, oppure via posta al Garante Privacy per la
              protezione dei Dati Personali che ha sede in Roma (Italia), Piazza
              Venezia n. 11 – Cap 00187, o alternativamente mediante ricorso
              all’Autorità giudiziaria.
            </Typography>

            {/* 11 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              11. Responsabile e incaricati
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px" }}
            >
              L’elenco aggiornato dei responsabili e degli incaricati al
              trattamento è custodito presso la sede del Titolare del
              Trattamento.
            </Typography>

            {/* 12 */}
            <Typography className="text-title" style={{ marginBottom: "20px" }}>
              12. Modifiche alla presente informativa
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "40px" }}
            >
              La presente informativa può subire variazioni. Si consiglia,
              quindi, di controllare regolarmente la presente informativa e di
              riferirsi alla versione più aggiornata.
            </Typography>

            <Typography
              className="text-privacy"
              style={{ marginBottom: "20px", fontStyle: "italic" }}
            >
              Ultimo aggiornamento: 16/05/2023
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
