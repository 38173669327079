import React, { useEffect, useState } from "react";
import ImgHeaderHome from "../../src/assets/img/header-home.jpg";
import SectionOne from "../components/AboutUs/SectionOne";
import SectionLexia from "../components/AboutUs/SectionLexia";
import SectionWant from "../components/AboutUs/SectionWant";
import SectionOurValue from "../components/AboutUs/SectionOurValues";
import TrustPilotAboutUs from "../components/AboutUs/TrustPilotAboutUs";
import SectionWorkWithUs from "../components/AboutUs/SectionWorkWithUs";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import { Helmet } from "react-helmet";

const AboutUsPage: React.FC = () => {
  const [canonicalUrl, setCanonicalUrl] = useState("");

  useEffect(() => {
    setCanonicalUrl(window.location.origin + window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar />
      <SectionOne />
      <SectionLexia />
      <SectionWant />
      <SectionOurValue />
      <TrustPilotAboutUs />
      <SectionWorkWithUs />
      <Footer />
    </>
  );
};

export default AboutUsPage;
