import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/AboutUs/SectionOurValues.css";

interface SectionOurValueProps {}

const SectionOurValue: React.FC<SectionOurValueProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Grid
        container
        className="bg-light container-aboutUs-SectionOurValues"
        justifyContent="center"
      >
        <Grid
          item
          xs={10}
          md={8}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography
            component="h1"
            className="color-primary title-aboutUs-SectionOurValues"
          >
            I valori in cui crediamo
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={9}
          className="container-card-aboutUs-SectionOurValues"
          spacing={4}
        >
          <Grid item xs={10} md={5} className="card-aboutUs-SectionOurValues">
            <Typography
              className="title-card-SectionOurValues color-primary"
              component="h5"
            >
              Sicurezza
            </Typography>
            <Typography
              className="color-primary text-20"
              style={{ textAlign: "center" }}
            >
              Abbiamo una grande responsabilità nei confronti di chi si affida a
              noi per le proprie pratiche, per questo motivo lavoriamo per
              offrire certezze ai nostri clienti. Dietro ogni processo c’è l’
              <span style={{ fontWeight: "bold" }}>
                esperienza di professionisti affidabili e competenti
              </span>
              , in grado di garantire un aiuto concreto e sicuro.
            </Typography>
          </Grid>

          <Grid item xs={10} md={5} className="card-aboutUs-SectionOurValues">
            <Typography
              className="title-card-SectionOurValues color-primary"
              component="h5"
            >
              Professionalità
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              className="color-primary text-20"
            >
              Conosciamo il nostro mestiere e lo facciamo con trasparenza e
              amore. Guidiamo i nostri clienti in ogni fase del processo, per{" "}
              <span style={{ fontWeight: "bold" }}>
                rendere semplice e chiaro
              </span>{" "}
              ciò che generalmente è difficile da comprendere. Ciò che conta per
              noi è quello che rendiamo possibile per gli altri.
            </Typography>
          </Grid>

          <Grid item xs={10} md={5} className="card-aboutUs-SectionOurValues">
            <Typography
              className="title-card-SectionOurValues color-primary"
              component="h5"
            >
              Ambizione
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              className="color-primary text-20"
            >
              Fissiamo obiettivi alti, per ottenere nuovi traguardi e{" "}
              <span style={{ fontWeight: "bold" }}>
                non smettere mai di crescere
              </span>
              . Vogliamo ampliare i nostri confini per assicurare servizi sempre
              in linea con le sfide del mercato.
            </Typography>
          </Grid>

          <Grid item xs={10} md={5} className="card-aboutUs-SectionOurValues">
            <Typography
              className="title-card-SectionOurValues color-primary"
              component="h5"
            >
              Innovazione
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              className="color-primary text-20"
            >
              Studiamo e integriamo nuove tecnologie e strumenti per creare
              <span style={{ fontWeight: "bold" }}>
                {" "}
                esperienze digitali fluide, semplici e all’avanguardia
              </span>
              . Ricerchiamo il giusto equilibrio tra velocità e qualità in ogni
              momento, per identificare le soluzioni tecnologiche più
              funzionali.
            </Typography>
          </Grid>

          <Grid item xs={10} md={5} className="card-aboutUs-SectionOurValues">
            <Typography
              className="title-card-SectionOurValues color-primary"
              component="h5"
            >
              Dedizione
            </Typography>
            <Typography
              className="color-primary text-20"
              style={{ textAlign: "center" }}
            >
              Crediamo che lavorando con impegno e intelligenza si possa
              costruire un futuro migliore, per tutti. Per questo motivo{" "}
              <span style={{ fontWeight: "bold" }}>
                ci sforziamo per dare sempre il massimo
              </span>{" "}
              e per confermare la fiducia che riceviamo.
            </Typography>
          </Grid>

          <Grid item xs={10} md={5} className="card-aboutUs-SectionOurValues">
            <Typography
              className="title-card-SectionOurValues color-primary"
              component="h5"
            >
              Collaborazione
            </Typography>
            <Typography
              className="color-primary text-20"
              style={{ textAlign: "center" }}
            >
              Il nostro è un ambiente trasversale: vige la{" "}
              <span style={{ fontWeight: "bold" }}>
                regola della trasparenza e della condivisione
              </span>{" "}
              in ogni cosa, affinché ognuno si possa sentire libero di esprimere
              il proprio pensiero sempre e in tutti i settori.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionOurValue;
