import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../../assets/css/Navbar/navbar.css";
import "../../assets/css/Global.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleUp,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { LocalPhone } from "@mui/icons-material";

declare global {
  interface Window {
    Calendly: any;
  }
}

const NavbarDesktop: React.FC = () => {
  const location = useLocation();

  library.add(faAngleDown);
  library.add(faAngleUp);
  library.add(faHouse);

  // Gestione menu soluzioni
  // navbar pc
  const [soluzioniVisible, setSoluzioniVisible] = useState(false);
  const [risorseVisible, setRisorseVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const soluzioniRef = useRef<HTMLLIElement>(null);
  const risorseRef = useRef<HTMLLIElement>(null);

  const handleMenuClick = (id: number) => {
    if (id === 1) {
      setSoluzioniVisible(!soluzioniVisible);
      if (risorseVisible) {
        setRisorseVisible(false);
      }
    }

    if (id === 2) {
      setRisorseVisible(!risorseVisible);
      if (soluzioniVisible) {
        setSoluzioniVisible(false);
      }
    }
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        (soluzioniRef.current &&
          !soluzioniRef.current.contains(event.target) &&
          soluzioniVisible &&
          !event.target.closest(".soluzioni-dropdown")) ||
        (risorseRef.current &&
          !risorseRef.current.contains(event.target) &&
          risorseVisible &&
          !event.target.closest(".soluzioni-dropdown"))
      ) {
        setSoluzioniVisible(false);
        setRisorseVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [soluzioniVisible, risorseVisible]);

  useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.pageYOffset);
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Integrazione Calendly
  useEffect(() => {
    const link = document.createElement("link");
    link.href = "https://calendly.com/assets/external/widget.css";
    link.rel = "stylesheet";
    document.head.appendChild(link);

    const script = document.createElement("script");
    script.src = "https://calendly.com/assets/external/widget.js";
    script.type = "text/javascript";
    script.async = true;
    document.head.appendChild(script);

    const handleCalendlyEvent = (e: MessageEvent) => {
      // Gestione degli eventi di Calendly qui (se necessario)
    };

    script.onload = () => {
      setTimeout(() => {
        if (
          window.Calendly &&
          typeof window.Calendly.initInlineWidgets === "function"
        ) {
          window.Calendly.initInlineWidgets();
        }
      }, 1000);
      window.addEventListener("message", handleCalendlyEvent);
    };

    return () => {
      window.removeEventListener("message", handleCalendlyEvent);
    };
  }, []);

  const openCalendlyPopup = () => {
    if (window.Calendly) {
      window.Calendly.showPopupWidget(
        "https://calendly.com/legalfarm/telefonata-informativa"
      );
    }
  };

  return (
    <>
      {/* pc   */}
      <nav
        className="navbar navbar-expand navbar-pc pt-3 fixed-top bg-light"
        style={{ position: "sticky", top: 0 }}
      >
        <div className="container-fluid mx-2 my-2">
          <a
            href={"/"}
            style={{ textDecoration: "none", marginLeft: "20px" }}
            className="color-primary"
          >
            <div className="logo"></div>
          </a>

          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i className="bi bi-list icon-hamburger bg-primary"></i>
              <i className="bi bi-list icon-hamburger bg-primary"></i>
              <i className="bi bi-list icon-hamburger bg-primary"></i>
            </span>
          </button> */}

          <div
            className="collapse navbar-collapse col-6 col-md-7"
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto me-3">
              {/* HOME */}
              {/* <li
                className="nav-item container-link"
                style={{ marginRight: "10px" }}
              >
                <a
                  className="nav-link"
                  href="/"
                  style={{ marginTop: "-1px", marginRight: "-10px" }}
                >
                  <div
                    className={`${
                      location.pathname === "/"
                        ? "icon-home-active"
                        : "icon-home"
                    }`}
                  ></div>
                </a>
              </li> */}

              {/* SOLUZIONI  */}
              <li
                className="nav-item container-link margin-link"
                ref={soluzioniRef}
              >
                <a
                  className="nav-link d-flex"
                  onClick={() => handleMenuClick(1)}
                >
                  <Typography
                    className={`${
                      location.pathname === "/registrazione-marchio-online" ||
                      location.pathname === "/cessione-quote-srl-online" ||
                      location.pathname === "/apertura-srl-online" ||
                      location.pathname === "/firma-digitale"
                        ? "link color-yellow"
                        : "link color-primary"
                    }`}
                  >
                    Soluzioni
                  </Typography>
                  {soluzioniVisible ? (
                    <FontAwesomeIcon
                      className={`icon-dropdown ${
                        location.pathname === "/registrazione-marchio-online" ||
                        location.pathname === "/cessione-quote-srl-online" ||
                        location.pathname === "/apertura-srl-online" ||
                        location.pathname === "/firma-digitale"
                          ? "color-yellow"
                          : "color-primary"
                      }`}
                      icon={faAngleUp}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={`icon-dropdown ${
                        location.pathname === "/registrazione-marchio-online" ||
                        location.pathname === "/cessione-quote-srl-online" ||
                        location.pathname === "/apertura-srl-online" ||
                        location.pathname === "/firma-digitale"
                          ? "color-yellow"
                          : "color-primary"
                      }`}
                      icon={faAngleDown}
                    />
                  )}
                </a>
              </li>

              {/* RISORSE  */}
              <li
                className="nav-item container-link margin-link "
                ref={risorseRef}
                style={{ marginRight: "10px" }}
              >
                <a
                  className="nav-link d-flex"
                  onClick={() => handleMenuClick(2)}
                >
                  <Typography
                    className={`link ${
                      location.pathname === "/faq" || location.pathname === "/b"
                        ? "color-yellow"
                        : "color-primary"
                    }`}
                  >
                    Risorse
                  </Typography>
                  {risorseVisible ? (
                    <FontAwesomeIcon
                      className={`icon-dropdown ${
                        location.pathname === "/faq" ||
                        location.pathname === "/b"
                          ? "color-yellow"
                          : "color-primary"
                      }`}
                      icon={faAngleUp}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={`icon-dropdown ${
                        location.pathname === "/faq" ||
                        location.pathname === "/b"
                          ? "color-yellow"
                          : "color-primary"
                      }`}
                      icon={faAngleDown}
                    />
                  )}
                </a>
              </li>

              {/* AZIENDA  */}
              <li className="nav-item container-link margin-link">
                <a className="nav-link" href="/azienda">
                  <Typography
                    className={`link ${
                      location.pathname === "/azienda"
                        ? "color-yellow"
                        : "color-primary"
                    }`}
                  >
                    Azienda
                  </Typography>
                </a>
              </li>

              {/* CTA CONSULENZA */}
              <li className="nav-item">
                <a
                  className="nav-link button-consulenza button-bg-cta-blue"
                  onClick={openCalendlyPopup}
                >
                  <div style={{ display: "flex" }}>
                    <LocalPhone
                      style={{ fontSize: "20px" }}
                      className="color-light"
                    />
                    <Typography
                      style={{
                        marginLeft: "10px",
                        marginTop: "1px",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                      className="color-light"
                    >
                      Richiedi info
                    </Typography>
                  </div>
                </a>
              </li>

              {/* ACCEDI */}
              <li className="nav-item marginButton">
                <a
                  className="nav-link button-login button-bg-cta"
                  href="https://app.legalfarm.it/sign-up"
                >
                  <Typography className="text-button color-primary">
                    Accedi
                  </Typography>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* menu soluzioni */}
      <Grid
        container
        className="soluzioni-dropdown bg-purple"
        style={{
          display: "flex",
          justifyContent: "center",
          height: soluzioniVisible ? "55vh" : "0",
          position: "fixed",
          top: "58px",
          zIndex: 1000,
        }}
      >
        <Grid
          item
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: soluzioniVisible ? 1 : 0,
            transition: `opacity 0.1s ${soluzioniVisible ? "0.1s" : "0s"}`,
          }}
        >
          <Grid
            component="a"
            href="/apertura-srl-online"
            item
            xs={2.1}
            style={{ textDecoration: "none", marginTop: "10px" }}
            className="card-soluzioni bg-light link-soluzioni"
          >
            {/* <a className="link-soluzioni"> */}
            <Typography
              className="title-card-soluzioni color-primary"
              style={{ textAlign: "center" }}
            >
              Apertura Srl
            </Typography>
            {/* </a> */}
          </Grid>

          <Grid
            item
            component="a"
            xs={2.1}
            href="/cessione-quote-srl-online"
            className="card-soluzioni bg-light"
            style={{
              textDecoration: "none",
              marginLeft: "40px",
              marginTop: "10px",
            }}
          >
            {/* <a className="link-soluzioni"> */}
            <Typography
              className="title-card-soluzioni color-primary"
              style={{ textAlign: "center" }}
            >
              Cessione quote Srl
            </Typography>
            {/* </a> */}
          </Grid>

          <Grid
            item
            component="a"
            href="/registrazione-marchio-online"
            xs={2.1}
            className="card-soluzioni bg-light link-soluzioni"
            style={{
              textDecoration: "none",
              marginLeft: "40px",
              marginTop: "10px",
            }}
          >
            {/* <a> */}
            <Typography
              className="title-card-soluzioni color-primary"
              style={{ textAlign: "center" }}
            >
              Registrazione marchio
            </Typography>
            {/* </a> */}
          </Grid>

          <Grid
            item
            component="a"
            xs={2.1}
            href="/firma-digitale"
            className="card-soluzioni bg-light link-soluzioni"
            style={{
              textDecoration: "none",
              marginLeft: "40px",
              marginTop: "10px",
            }}
          >
            {/* <a> */}
            <Typography
              className="title-card-soluzioni color-primary"
              style={{ textAlign: "center" }}
            >
              Attivazione firma digitale
            </Typography>
            {/* </a> */}
          </Grid>
        </Grid>
      </Grid>

      {/* menu risorse  */}
      <Grid
        container
        className="soluzioni-dropdown bg-purple"
        style={{
          display: "flex",
          justifyContent: "center",
          height: risorseVisible ? "55vh" : "0",
          position: "fixed",
          top: "58px",
          zIndex: 1000,
        }}
      >
        <Grid
          item
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: risorseVisible ? 1 : 0,
          }}
        >
          <Grid
            component="a"
            href="/faq"
            item
            xs={2.1}
            style={{ textDecoration: "none", marginTop: "10px" }}
            className="card-risorse bg-light link-soluzioni"
          >
            <Typography
              className="title-card-soluzioni color-primary"
              style={{ textAlign: "center" }}
            >
              Faq
            </Typography>
          </Grid>

          <Grid
            item
            component="a"
            xs={2.1}
            href="/b"
            className="card-risorse bg-light link-soluzioni"
            style={{
              marginLeft: "40px",
              textDecoration: "none",
              marginTop: "10px",
            }}
          >
            <Typography
              className="title-card-soluzioni color-primary"
              style={{ textAlign: "center" }}
            >
              Blog
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NavbarDesktop;
