import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Trademark/AdvantagesTrademark.css";

interface AdvantagesTrademarkProps {}

const AdvantagesTrademark: React.FC<AdvantagesTrademarkProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      className="container-AdvantagesTrademark"
      style={{ display: "flex", flexDirection: md ? "row" : "column-reverse" }}
    >
      <Grid
        item
        className="container-AdvantagesTrademark-content"
        xs={12}
        md={6}
      >
        <Grid
          item
          xs={9}
          sm={10}
          md={12}
          className="container-AdvantagesTrademark-text"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h2"
            className="title-AdvantagesTrademark color-primary"
          >
            Che vantaggi avrai
          </Typography>

          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={1}>
              <div className="icon-team-trademark"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="color-primary description-AdvantagesTrademark">
                pratica di{" "}
                <span style={{ fontWeight: "bold" }} className="color-primary">
                  registrazione seguita da un professionista
                </span>{" "}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginTop: "50px" }}
          >
            <Grid item xs={1}>
              <div className="icon-pig"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="description-AdvantagesTrademark color-primary">
                <span className="color-primary" style={{ fontWeight: "bold" }}>
                  costi accessibili e contenuti
                </span>{" "}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginTop: "50px" }}
          >
            <Grid item xs={1}>
              <div className="icon-online-trademark"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="description-AdvantagesTrademark color-primary">
                deposito interamente eseguito{" "}
                <span style={{ fontWeight: "bold" }}>online</span>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "30px",
              justifyContent: "center",
            }}
          >
            <div className="container-button-cta-final">
              <a
                className="nav-link button-cta-final"
                href="https://app.legalfarm.it/sign-up?redirect=trademark"
              >
                <Typography
                  className="color-primary text-20"
                  style={{ fontWeight: 600 }}
                >
                  Inizia subito
                </Typography>
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4.5} style={{ height: "100%" }}>
        <Grid item xs={12} className="container-img-AdvantagesTrademark">
          <div className="img-AdvantagesTrademark"></div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdvantagesTrademark;
