import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Logo from "../../assets/img/costituzioneSrl_logo.png";
import "../../assets/css/Navbar/navbar.css";
import "../../assets/css/Global.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import NavbarDesktop from "./NavbarDesktop";
import NavbarSmart from "./NavbarSmart";

const lngs: {
  en: { icon: React.ReactNode };
  it: { icon: React.ReactNode };
} = {
  en: { icon: "it" },
  it: { icon: "en" },
};

const Navbar: React.FC = () => {
  return (
    <>
      <NavbarSmart />
      <NavbarDesktop />
    </>
  );
};

export default Navbar;
