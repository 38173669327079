import React, { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../assets/css/Trademark/FaqTrademark.css";
import "../../assets/css/Generics/FaqGlobal.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Close } from "@mui/icons-material";
import TrademarkFaq from "../Generics/AccordionFaq/TrademarkFaq";

interface FaqTrademarkProps {}

const FaqTrademark: React.FC<FaqTrademarkProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  // modal

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid
        container
        className="container-Faq bg-orange-dark"
        style={{ display: "flex" }}
      >
        <Grid
          item
          xs={10}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" className="color-light title-Faq">
            FAQ
          </Typography>
        </Grid>

        <Grid item xs={10} md={8} className="container-accordion-faq">
          <Accordion
            style={{
              marginBottom: "40px",
              borderRadius: "5px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              style={{ padding: "5px 25px", borderRadius: "10px" }}
              expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
            >
              <Typography
                className="color-orange-dark text-20"
                style={{ fontWeight: "bold" }}
              >
                Cosa è incluso nel servizio?
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details-faq">
              <Typography
                className="color-orange-dark text-accordion-faq"
                style={{ marginBottom: "10px" }}
              >
                Il nostro servizio include l’intera gestione della pratica con
                l’assistenza di un professionista sempre a disposizione.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{
              marginBottom: "40px",
              borderRadius: "5px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              style={{ padding: "5px 25px", borderRadius: "10px" }}
              expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
            >
              <Typography
                className="color-orange-dark text-20"
                style={{ fontWeight: "bold" }}
              >
                Cosa mi serve per completare la pratica?
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details-faq">
              <Typography className="color-orange-dark text-accordion-faq">
                Sono sufficienti la copia del tuo documento d’identità, una
                descrizione del marchio e alcuni file con il logo e i colori
                dello stesso. Non preoccuparti, ti guideremo passo per passo sui
                documenti da caricare. Se intendi registrare il marchio a nome
                di una società, servirà anche una visura aggiornata di
                quest’ultima.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{
              marginBottom: "40px",
              borderRadius: "5px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              style={{ padding: "5px 25px", borderRadius: "10px" }}
              expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
            >
              <Typography
                className="color-orange-dark text-20"
                style={{ fontWeight: "bold" }}
              >
                Quali sono le tempistiche per ottenere il certificato di
                deposito?
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details-faq">
              <Typography className="color-orange-dark text-accordion-faq">
                L’invio della pratica da parte nostra è immediato non appena ci
                fornisci tutti i dati necessari. Bisogna poi attendere i tempi
                tecnici degli enti competenti a evadere la pratica. In media,
                sono necessari dai 10 ai 30 giorni. Purtroppo, queste
                tempistiche non dipendono da noi ma sollecitiamo regolarmente
                gli enti in caso di lunghe attese.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              onClick={handleOpenModal}
              component="a"
              className="color-light"
              style={{ cursor: "pointer" }}
            >
              Vedi tutte le domande frequenti sul servizio di registrazione
              marchio
            </Typography>
          </div>
        </Grid>
      </Grid>

      {/* modal  */}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={openModal}>
          <Grid container sx={{ width: "80%" }}>
            <Grid
              item
              xs={12}
              sx={{
                position: "absolute",
                width: "80%",
                top: 100,
                left: "50%",
                transform: "translate(-50%, 0%)",
                bgcolor: "background.paper",
                border: "none",
                boxShadow: 24,
                borderRadius: "5px",
                maxHeight: "80vh",
                overflowY: "auto",
                padding: "20px 30px 30px 30px",
              }}
            >
              <Grid
                container
                style={{ marginTop: "20px", marginBottom: "50px" }}
              >
                <Grid item xs={8}>
                  {" "}
                  <Typography
                    className="color-orange-dark text-20"
                    style={{
                      fontWeight: "bold",
                      marginLeft: "15px",
                    }}
                  >
                    FAQ REGISTRAZIONE MARCHIO
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    className="close-modal-faq"
                    onClick={handleCloseModal}
                  >
                    <Close
                      className="color-orange-dark"
                      style={{ marginRight: "7px", marginTop: "-8px" }}
                    />
                  </IconButton>
                </Grid>
              </Grid>

              <div>
                <TrademarkFaq />
              </div>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

export default FaqTrademark;
