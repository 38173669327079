import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Trademark/HowToRegisterTrademark.css";

interface HowToRegisterTrademarkProps {}

const HowToRegisterTrademark: React.FC<HowToRegisterTrademarkProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="bg-purple container-card-services-trademark">
      <Grid
        item
        xs={8}
        sm={10}
        md={10}
        lg={6}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          className="title-card-services-trademark color-light"
        >
          Come registrare il tuo marchio
        </Typography>
      </Grid>

      <Grid
        md={10}
        xs={12}
        item
        container
        className="box-card-service-trademark"
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={10} className="bubbles-container">
          <Grid
            item
            xs={12}
            className="container-interno-HowToRegisterDigitalSignature"
          >
            <div
              className="bubble bubble-text first-bubble color-purple"
              style={{ width: "80px" }}
            >
              1
            </div>

            <div className="box-text">
              <p className="description-bubble p-0 m-0 color-light">
                Carica nel nostro portale il tuo documento d’identità e{" "}
                <span style={{ fontWeight: "bold" }}>
                  documenti relativi al marchio
                </span>{" "}
                che vuoi depositare.
              </p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="container-interno-HowDoesThatShareTransfer"
          >
            <div
              className="bubble bubble-text second-bubble color-purple"
              style={{ width: "80px" }}
            >
              2
            </div>
            <div className="box-text">
              <p className="description-bubble p-0 m-0 color-light">
                Scegli una o più{" "}
                <span style={{ fontWeight: "bold" }}>classi merceologiche</span>{" "}
                a seconda del prodotto o del servizio che offri. Penseremo noi a
                tutti gli altri passaggi.
              </p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="container-interno-HowDoesThatShareTransfer"
          >
            <div
              className="bubble bubble-text three-bubble color-purple"
              style={{ width: "80px" }}
            >
              3
            </div>
            <div className="box-text">
              <p className="description-bubble p-0 m-0 mt-3 color-light">
                Ricevi il{" "}
                <span style={{ fontWeight: "bold" }}>
                  certificato di registrazione
                </span>{" "}
                con validità di 10 anni.
              </p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="container-interno-HowDoesThatShareTransfer"
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <a
                className="nav-link button-cta-white"
                href="https://app.legalfarm.it/sign-up?redirect=trademark"
              >
                <Typography
                  className="color-light text-20"
                  style={{ fontWeight: 600 }}
                >
                  Inizia subito
                </Typography>
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HowToRegisterTrademark;
