import React, { useEffect } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/ShareTransfer/ShareTransferHero.css";
import Lottie from "lottie-web";
import animationHeroShareTransfer from "../../assets/animation/animationHeroShareTransfer.json";

interface ShareTransferHeroProps {}

const ShareTransferHero: React.FC<ShareTransferHeroProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    const container = document.getElementById("lottie-animation-three");

    if (container !== null) {
      const animation = Lottie.loadAnimation({
        container: container,
        renderer: "svg",
        animationData: animationHeroShareTransfer,
        loop: true,
        autoplay: true,
      });

      return () => {
        animation.destroy();
      };
    }
  }, []);

  return (
    <Grid
      container
      className="container-hero-shareTransfer"
      style={{
        height: "100%",
        flexDirection: md ? "row" : "column-reverse",
      }}
    >
      <Grid item className="box-hero-content-shareTransfer" xs={12} md={6}>
        <Grid item xs={11}>
          <Typography
            variant="h1"
            className="title-hero-shareTransfer color-primary"
          >
            Cessione quote Srl <br /> online, facile, rapida
          </Typography>
          <Typography className="description-hero-shareTransfer text-20 color-primary">
            Trasferisci le quote societarie senza bisogno del notaio,
            comodamente online e senza complicazioni.
          </Typography>

          <div className="container-button-shareTransfer">
            <a
              className="nav-link button-cta-shareTransfer button-bg-cta"
              href="https://app.legalfarm.it/sign-up?redirect=shareTransfer"
            >
              <Typography
                className="color-primary text-20"
                style={{ fontWeight: 600 }}
              >
                Inizia subito
              </Typography>
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={4.2}
        className="container-animation-hero-shareTransfer"
      >
        <div
          className="animation-hero-shareTransfer"
          id="lottie-animation-three"
        ></div>
      </Grid>
    </Grid>
  );
};

export default ShareTransferHero;
