import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/AboutUs/SectionOne.css";

interface SectionOneProps {}

const SectionOne: React.FC<SectionOneProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="bg-blue container-aboutUs-sectionOne">
      <Grid
        item
        xs={10}
        md={8}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Typography
          component="h1"
          className="color-light title-aboutUs-sectionOne"
        >
          Siamo qui per darti certezze
        </Typography>
        <Typography className="description-aboutUs-sectionOne text-20 color-light">
          Nasciamo da una forte vocazione per le{" "}
          <span style={{ fontWeight: "bold" }}>
            soluzioni tech e innovative
          </span>
          : il nostro obiettivo è quello di supportare gli imprenditori nella
          gestione quotidiana della propria azienda,{" "}
          <span style={{ fontWeight: "bold" }}>riducendo la burocrazia</span>.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SectionOne;
