import React, { useEffect } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Trademark/HeroTrademark.css";
import Lottie from "lottie-web";
import animationHeroTrademark from "../../assets/animation/animationHeroTrademark.json";

interface HeroTrademarkProps {}

const HeroTrademark: React.FC<HeroTrademarkProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    const container = document.getElementById("lottie-animation-three");

    if (container !== null) {
      const animation = Lottie.loadAnimation({
        container: container,
        renderer: "svg",
        animationData: animationHeroTrademark,
        loop: true,
        autoplay: true,
      });

      return () => {
        animation.destroy();
      };
    }
  }, []);

  return (
    <Grid
      container
      className="container-hero-trademark"
      style={{
        height: "100%",
        flexDirection: md ? "row" : "column-reverse",
      }}
    >
      <Grid item className="box-hero-content-trademark" xs={12} md={6}>
        <Grid item xs={11}>
          <Typography
            variant="h1"
            className="title-hero-trademark color-primary"
          >
            Registra il tuo marchio
          </Typography>
          <Typography className="description-hero-trademark text-20 color-primary">
            Tutela la tua identità aziendale: tramite il nostro portale potrai
            depositare online il tuo marchio in Italia o in Europa e proteggerlo
            da plagi e contraffazioni.
          </Typography>

          <div className="container-button-trademark">
            <a
              className="nav-link button-cta-trademark button-bg-cta"
              href="https://app.legalfarm.it/sign-up?redirect=trademark"
            >
              <Typography
                className="color-primary text-20"
                style={{ fontWeight: 600 }}
              >
                Registra subito
              </Typography>
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={4.2}
        className="container-animation-hero-trademark"
      >
        <div
          className="animation-hero-trademark"
          id="lottie-animation-three"
        ></div>
      </Grid>
    </Grid>
  );
};

export default HeroTrademark;
