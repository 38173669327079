import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import MuiTheme from "./themes";
import Blog from "./Pages/Blog";
import HomePage from "./Pages/Home";
import SrlPage from "./Pages/services/Srl";
import DigitalSignature from "./Pages/services/DigitalSignature";
import Trademark from "./Pages/services/Trademark";
import ShareTransfer from "./Pages/services/ShareTransfer";
import TermsConditions from "./Pages/Terms&Conditions";
import Privacy from "./Pages/Privacy";
import Cookie from "./Pages/Cookie";
import Faq from "./Pages/Faq";
import AboutUsPage from "./Pages/AboutUsPage";
import NotFound from "./Pages/Error404";
import BlogDetailPage from "./Pages/BlogDetail";

function App() {
  return (
    <ThemeProvider theme={MuiTheme}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/b" element={<Blog />} />
            <Route path="/b/:slug" element={<BlogDetailPage />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/apertura-srl-online" element={<SrlPage />} />
            <Route path="/firma-digitale" element={<DigitalSignature />} />
            <Route
              path="/registrazione-marchio-online"
              element={<Trademark />}
            />
            <Route
              path="/cessione-quote-srl-online"
              element={<ShareTransfer />}
            />
            <Route path="/azienda" element={<AboutUsPage />} />
            <Route path="/termini-e-condizioni" element={<TermsConditions />} />
            <Route path="/informativa-privacy" element={<Privacy />} />
            <Route path="/cookie" element={<Cookie />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
