import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Company/CryptoSmart.css";

interface CryptoSmartProps {}

const CryptoSmart: React.FC<CryptoSmartProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="container-crypto bg-blue">
      <Grid
        item
        xs={10}
        sm={10}
        md={10}
        lg={7}
        className="container-content-crypto-one"
      >
        <Typography variant="h2" className="title-crypto color-light">
          In più avrai un wallet crypto gratis
        </Typography>
        <Typography
          className="text-20 color-light"
          style={{ textAlign: "center" }}
        >
          Grazie alla partnership con{" "}
          <a
            href="https://app.cryptosmart.it/signup?referral=costituzioneSrl"
            target="_blank"
            className="color-light"
            style={{ fontWeight: "bold" }}
          >
            Cryptosmart
          </a>
          , leader italiano per gli exchange,{" "}
          <span style={{ fontWeight: "bold" }}>
            potrai aprire gratis il tuo portafoglio elettronico
          </span>{" "}
          per gestire con semplicità le valute digitali.
        </Typography>
      </Grid>

      <Grid
        item
        xs={10}
        sm={10}
        md={10}
        lg={7}
        className="container-list-crypto"
      >
        <div className="logo-cryptosmart"></div>
      </Grid>

      <Grid
        item
        xs={10}
        sm={10}
        md={10}
        lg={7}
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
        }}
      >
        <Typography variant="h3" className="title-two-crypto color-light">
          Cosa potrai fare con il tuo wallet
        </Typography>
        <div className="container-list-crypto">
          <ul>
            <li className="color-light" style={{ marginBottom: "10px" }}>
              <Typography className="color-light">
                potrai vendere o acquistare cryptomonete;
              </Typography>
            </li>
            <li className="color-light" style={{ marginBottom: "10px" }}>
              <Typography className="color-light">
                potrai tenere traccia diretta del mercato;
              </Typography>
            </li>
            <li className="color-light">
              <Typography className="color-light">
                potrai ricevere pagamenti in cryptovalute su un conto dedicato.
              </Typography>
            </li>
          </ul>
        </div>
      </Grid>
    </Grid>
  );
};

export default CryptoSmart;
