import React from "react";
import HeroDigitalSignature from "../../components/DigitalSignature/HeroDigitalSignature";
import MetricsDigitalSignature from "../../components/DigitalSignature/MetricsDigitalSignature";
import HowActiveDigitalSignature from "../../components/DigitalSignature/HowActiveDigitalSignature";
import AdvantagesDigitalSignature from "../../components/DigitalSignature/AdvantagesDigitalSignature";
import CardPriceDigitalSignature from "../../components/DigitalSignature/CardPriceDigitalSignature";
import FaqDigitalSignature from "../../components/DigitalSignature/FaqDigitalSignature";
import CtaDigitalSignature from "../../components/DigitalSignature/CtaDigitalSignature";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

const DigitalSignature: React.FC = () => {
  return (
    <>
      <Navbar />
      <HeroDigitalSignature />
      <MetricsDigitalSignature />
      <HowActiveDigitalSignature />
      <AdvantagesDigitalSignature />
      <CardPriceDigitalSignature />
      <FaqDigitalSignature />
      <CtaDigitalSignature />
      <Footer />
    </>
  );
};

export default DigitalSignature;
