import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import "../assets/css/Faq/HeaderFaq.css";
import "../assets/css/Faq/FaqPageGlobal.css";
import ShareTransferFaq from "../components/Generics/AccordionFaq/ShareTransferFaq";
import CompanyFaq from "../components/Generics/AccordionFaq/CompanyFaq";
import DigitalSignatureFaq from "../components/Generics/AccordionFaq/DigitalSignatureFaq";
import TrademarkFaq from "../components/Generics/AccordionFaq/TrademarkFaq";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import { Helmet } from "react-helmet";

const Faq: React.FC = () => {
  const [canonicalUrl, setCanonicalUrl] = useState("");

  useEffect(() => {
    setCanonicalUrl(window.location.origin + window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar />
      <Grid
        container
        className="container-header-faq"
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <Grid
          item
          xs={8}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography component="h5" className="color-primary title-header-faq">
            Faq
          </Typography>
        </Grid>

        <Grid item container xs={11} className="container-card-faq">
          <Grid
            component="a"
            href="#faqSrl"
            item
            xs={8}
            sm={5}
            md={2.3}
            className="card-srl-faq bg-purple color-light"
          >
            <Typography className="text-20" style={{ fontWeight: "bold" }}>
              Apertura <br /> Srl
            </Typography>
          </Grid>

          <Grid
            component="a"
            href="#faqShareTransfer"
            item
            xs={8}
            sm={5}
            md={2.3}
            className="bg-orange color-light card-shareTransfer-faq"
          >
            <Typography className="text-20" style={{ fontWeight: "bold" }}>
              Cessione quote <br /> Srl
            </Typography>
          </Grid>

          <Grid
            component="a"
            href="#faqTrademark"
            item
            xs={8}
            sm={5}
            md={2.3}
            className="card-trademark-faq bg-blue color-light"
          >
            <Typography
              className="text-20"
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              Registrazione <br /> marchio
            </Typography>
          </Grid>

          <Grid
            component="a"
            href="#faqDigitalSignature"
            item
            xs={8}
            sm={5}
            md={2.3}
            className="card-digitalSignature-faq bg-orange-dark color-light"
          >
            <Typography className="text-20" style={{ fontWeight: "bold" }}>
              Attivazione firma <br /> digitale
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* srl */}
      <Grid
        container
        id="faqSrl"
        className="container-faq-page bg-purple"
        style={{ display: "flex" }}
      >
        <Grid
          item
          xs={10}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" className="color-light title-faq-page">
            Faq apertura Srl online
          </Typography>
        </Grid>

        <Grid item xs={10} md={8} className="container-accordion-faq-page">
          <CompanyFaq PageFaq={true} />
        </Grid>
      </Grid>

      {/* cessione  */}
      <Grid
        container
        id="faqShareTransfer"
        className="container-faq-page bg-orange"
        style={{ display: "flex" }}
      >
        <Grid
          item
          xs={10}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" className="color-light title-faq-page">
            Faq cessione quote Srl
          </Typography>
        </Grid>

        <Grid item xs={10} md={8} className="container-accordion-faq-page">
          <ShareTransferFaq PageFaq={true} />
        </Grid>
      </Grid>

      {/* marchio */}
      <Grid
        id="faqTrademark"
        container
        className="container-faq-page bg-blue"
        style={{ display: "flex" }}
      >
        <Grid
          item
          xs={10}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" className="color-light title-faq-page">
            FAQ registrazione marchio online
          </Typography>
        </Grid>

        <Grid item xs={10} md={8} className="container-accordion-faq-page">
          <TrademarkFaq PageFaq={true} />
        </Grid>
      </Grid>

      {/* firma */}
      <Grid
        id="faqDigitalSignature"
        container
        className="container-faq-page bg-orange-dark"
        style={{ display: "flex" }}
      >
        <Grid
          item
          xs={10}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" className="color-light title-faq-page">
            Faq attivazione firma digitale
          </Typography>
        </Grid>

        <Grid item xs={10} md={8} className="container-accordion-faq-page">
          <DigitalSignatureFaq PageFaq={true} />
        </Grid>
      </Grid>

      <Footer />
    </>
  );
};

export default Faq;
