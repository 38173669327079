import React, { useEffect } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { KeyboardDoubleArrowRightOutlined } from "@mui/icons-material";
import "../../../../assets/css/Generics/CardBlog.css";

interface CardArticleBlogProps {}

interface Article {
  title: string;
  content: string;
  image_base64: string;
  meta: string;
  imgPreview: string;
}

interface CardArticleBlogProps {
  article: Article;
}

const CardArticleBlog: React.FC<CardArticleBlogProps> = ({ article }) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Card
        sx={{
          position: "relative",
          width: "345px",
          height: "350px",
          marginRight: "25px",
          marginLeft: "25px",
          marginBottom: "50px",
          boxShadow: "none",
          borderRadius: "5px",
        }}
      >
        <CardMedia
          sx={{ height: 140, backgroundSize: "cover" }}
          image={article.imgPreview ? `${article.imgPreview}` : ""}
        />
        <CardContent>
          <Typography
            className="color-primary"
            style={{
              fontSize: "18px",
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            {article.title}
          </Typography>
          <Typography
            className="color-primary"
            style={{ fontSize: "16px", paddingBottom: "10px", fontWeight: 300 }}
            variant="body2"
            color="text.secondary"
          >
            {article &&
              (() => {
                const span = document.createElement("span");
                span.innerHTML = article.content;
                const textContent = span.textContent || span.innerText || "";
                return textContent.length > 120
                  ? textContent.slice(0, 120) + "..."
                  : textContent;
              })()}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        >
          <a
            className="color-yellow"
            href={`/b/${article.meta}`}
            style={{ cursor: "pointer", fontWeight: 500 }}
          >
            Leggi{" "}
            <KeyboardDoubleArrowRightOutlined
              style={{ fontSize: "16px", marginLeft: "4px" }}
            />
          </a>
        </CardActions>
      </Card>
    </>
  );
};

export default CardArticleBlog;
