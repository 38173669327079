import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Generics/Cta-Final.css";

interface CtaTrademarkProps {
  ctaBlog?: boolean;
}

const CtaTrademark: React.FC<CtaTrademarkProps> = ({ ctaBlog }) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      className={` ${
        ctaBlog ? "bg-blue container-cta-final" : "container-cta-final"
      }`}
      style={{ display: "flex" }}
    >
      <Grid
        item
        xs={10}
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h2"
          className={` ${
            ctaBlog
              ? "color-light title-cta-final"
              : "color-primary title-cta-final"
          }`}
        >
          La soluzione tech per la registrazione del tuo marchio
        </Typography>
        <Typography
          className={` ${
            ctaBlog
              ? "color-light description-TechSolution"
              : "color-primary description-TechSolution"
          }`}
        >
          Risparmia tempo, automatizza le attività legali e{" "}
          <span
            className={` ${ctaBlog ? "color-light" : "color-primary"}`}
            style={{ fontWeight: "bold" }}
          >
            paga solo quello che ti serve
          </span>
          .
        </Typography>
        <div className="container-button-cta-final">
          <a
            className={` ${
              ctaBlog
                ? "nav-link button-cta-final-blog"
                : "nav-link button-cta-final"
            }`}
            href={`${
              ctaBlog
                ? "/registrazione-marchio-online"
                : "https://app.legalfarm.it/sign-up?redirect=trademark"
            }`}
          >
            <Typography
              className={` ${
                ctaBlog ? "color-light text-20" : "color-primary text-20"
              }`}
              style={{ fontWeight: 600 }}
            >
              Registra subito
            </Typography>
          </a>
        </div>

        {/* Contattaci */}
        <div className="container-button-contact">
          {/* <a className="nav-link" href=""> */}
          <Typography
            className={` ${
              ctaBlog ? "color-light text-20" : "color-primary text-20"
            }`}
          >
            Hai domande o dubbi? Contattaci in chat!
          </Typography>
          {/* </a> */}
        </div>
      </Grid>
    </Grid>
  );
};

export default CtaTrademark;
