import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/AboutUs/SectionWant.css";

interface SectionWantProps {}

const SectionWant: React.FC<SectionWantProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="bg-purple container-aboutUs-sectionWant">
      <Grid
        item
        xs={10}
        md={8}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Typography
          component="h1"
          className="color-light title-aboutUs-sectionWant"
        >
          Cosa desideriamo
        </Typography>
        <Typography className="description-aboutUs-sectionWant text-20 color-light">
          Vogliamo{" "}
          <span style={{ fontWeight: "bold" }}>
            accelerare la transizione verso servizi legali interamente digitali
            e automatizzati
          </span>
          , liberando gli imprenditori dalla complessa burocrazia
          amministrativa, da tutti quegli ostacoli che rallentano la crescita
          del proprio business.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SectionWant;
