import React from "react";
import ShareTransferHero from "../../components/ShareTransfer/ShareTransferHero";
import ShareTransferMetrics from "../../components/ShareTransfer/ShareTransferMetrics";
import HowDoesThatWorkShareTransfer from "../../components/ShareTransfer/HowDoesThatWorkShareTransfer";
import WhySellWithUsShareTransfer from "../../components/ShareTransfer/WhySellWithUsShareTransfer";
import CardPriceShareTransfer from "../../components/ShareTransfer/CardPriceShareTransfer";
import FaqShareTransfer from "../../components/ShareTransfer/FaqShareTransfer";
import CtaShareTransfer from "../../components/ShareTransfer/CtaShareTransfer";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

const ShareTransfer: React.FC = () => {
  return (
    <>
      <Navbar />
      <ShareTransferHero />
      <ShareTransferMetrics />
      <HowDoesThatWorkShareTransfer />
      <WhySellWithUsShareTransfer />
      <CardPriceShareTransfer />
      <FaqShareTransfer />
      <CtaShareTransfer />
      <Footer />
    </>
  );
};

export default ShareTransfer;
