import React from "react";
import { Grid, Typography } from "@mui/material";
import Lottie from "lottie-web";
import animationError404 from "../assets/animation/animation-error404.json";
import Footer from "../components/Footer/Footer";
import "../../src/assets/css/Error404.css";

const NotFound = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        height: "100vh",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "-80px",
        }}
        textAlign="center"
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="img-error404"></div>
        </div>

        <Typography
          className="color-primary"
          style={{ fontSize: "42px", color: "#fff", fontWeight: 600 }}
        >
          Ops... qualcosa è andato storto
        </Typography>
        <Typography
          className="color-primary text-20"
          style={{ color: "#fff", marginTop: "10px" }}
        >
          Pagina non trovata. Ricarica oppure{" "}
          <a href="/" className="color-primary">
            torna alla home
          </a>
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <div className="logo"></div>
        </div>
      </Grid>
    </Grid>
  );
};

export default NotFound;
