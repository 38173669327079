import React, { useEffect, useState } from "react";
import HeroHome from "../components/Homepage/HeroHome";
import Metrics from "../components/Homepage/Metrics";
import CardServices from "../components/Homepage/CardServices";
import WhyChooseUs from "../components/Homepage/WhyChooseUs";
import TheyChoseUs from "../components/Homepage/TheyChoseUs";
import TechSolution from "../components/Homepage/TechSolution";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import { Helmet } from "react-helmet";

const HomePage: React.FC = () => {
  const [canonicalUrl, setCanonicalUrl] = useState("");

  useEffect(() => {
    setCanonicalUrl(window.location.origin + window.location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar />
      <HeroHome />
      <Metrics />
      <CardServices />
      <WhyChooseUs />
      <TheyChoseUs />
      <TechSolution />
      <Footer />
    </>
  );
};

export default HomePage;
