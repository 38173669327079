import React, { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../../assets/css/Generics/FaqGlobal.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface DigitalSignatureFaqProps {
  PageFaq?: boolean;
}

const DigitalSignatureFaq: React.FC<DigitalSignatureFaqProps> = ({
  PageFaq,
}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {" "}
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
        >
          <Typography
            className="color-orange-dark text-20"
            style={{ fontWeight: "bold" }}
          >
            Cos’è una firma digitale?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className="color-orange-dark text-accordion-faq"
            style={{ marginBottom: "10px" }}
          >
            La firma digitale è l’equivalente informatico della tradizionale
            firma autografa (fatta a mano). Alla firma digitale è riconosciuto
            pieno valore legale quando viene rilasciata da un ente certificatore
            autorizzato (es. Aruba).
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
        >
          <Typography
            className="color-orange-dark text-20"
            style={{ fontWeight: "bold" }}
          >
            Come funziona la firma digitale remota?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className="color-orange-dark text-accordion-faq"
            style={{ marginBottom: "10px" }}
          >
            Una firma digitale è remota quando i dati del suo titolare vengono
            conservati su un server custodito da un ente certificatore. In
            questo modo, il titolare della firma può utilizzarla direttamente
            dallo smartphone, senza dover conservare le relative credenziali in
            altri dispositivi (es. chiavette usb).
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
        >
          <Typography
            className="color-orange-dark text-20"
            style={{ fontWeight: "bold" }}
          >
            Cosa mi serve per richiederla?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography
            className="color-orange-dark text-accordion-faq"
            style={{ marginBottom: "10px" }}
          >
            Bastano i tuoi dati anagrafici, un documento di identità in corso di
            validità e la tua tessera sanitaria (codice fiscale).
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
        >
          <Typography
            className="color-orange-dark text-20"
            style={{ fontWeight: "bold" }}
          >
            Come funziona il processo di attivazione?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography className="color-orange-dark text-accordion-faq">
            Dopo la tua richiesta, ti basta caricare sul nostro portale una
            copia del passaporto o della carta d’identità e della tessera
            sanitaria. Potrai quindi fissare un appuntamento in videocall con un
            operatore certificato. L’operatore accerterà la tua identità in soli
            5 minuti. Al termine della videocall ti verrà rilasciato un link con
            cui potrai attivare immediatamente la firma.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
        >
          <Typography
            className="color-orange-dark text-20"
            style={{ fontWeight: "bold" }}
          >
            Cosa è incluso nel servizio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography className="color-orange-dark">
            <ul className="color-orange-dark">
              <li>
                <Typography
                  className="color-orange-dark text-accordion-faq"
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  la videocall con l’operatore certificato per il riconoscimento
                  della tua identità
                </Typography>
              </li>
              <li>
                <Typography
                  className="color-orange-dark text-accordion-faq"
                  style={{ marginBottom: "5px" }}
                >
                  {" "}
                  il rilascio di una firma digitale remota Aruba utilizzabile da
                  smartphone
                </Typography>
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
        >
          <Typography
            className="color-orange-dark text-20"
            style={{ fontWeight: "bold" }}
          >
            Quali sono le tempistiche per l'attivazione?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography className="color-orange-dark text-accordion-faq">
            Sono davvero molto brevi! Potrai fissare immediatamente
            l’appuntamento per il riconoscimento in videocall per ottenere
            l’autorizzazione al rilascio della firma. In genere, l’attivazione
            avviene entro 24/48 ore.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
        >
          <Typography
            className="color-orange-dark text-20"
            style={{ fontWeight: "bold" }}
          >
            Come posso pagare?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography className="color-orange-dark text-accordion-faq">
            Puoi acquistare il servizio pagando tramite PayPal (conto corrente
            associato o semplice carta di credito abilitata agli acquisti
            online).
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "20px",
          borderRadius: "5px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{ borderRadius: "10px" }}
          expandIcon={<ExpandMoreIcon className="color-orange-dark" />}
        >
          <Typography
            className="color-orange-dark text-20"
            style={{ fontWeight: "bold" }}
          >
            Il servizio prevede un abbonamento?
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "20px" }}>
          <Typography className="color-orange-dark text-accordion-faq">
            Assolutamente no! Paghi soltanto la firma digitale che hai
            richiesto. Dopo 1 anno, potrai scegliere se rinnovare il servizio di
            firma direttamente sul portale Aruba oppure sospenderlo.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DigitalSignatureFaq;
