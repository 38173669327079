import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import { Grid } from "@mui/material";

const Cookie: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://consent.cookiebot.com/b51a8ace-d28c-4b9d-8aa2-ddfb76f3e4de/cd.js";
    script.type = "text/javascript";
    script.async = true;

    const container = document.getElementById("CookieDeclaration");

    if (container) {
      container.appendChild(script);
    }
  }, []);

  return (
    <>
      <Navbar />
      <Grid container style={{ display: "flex", justifyContent: "center", marginTop: '50px' }}>
        <Grid item xs={10}>
          <h1>Informativa cookie</h1>
          <div
            id="CookieDeclaration"
            // src="https://consent.cookiebot.com/b51a8ace-d28c-4b9d-8aa2-ddfb76f3e4de/cd.js"
            // type="text/javascript"
            // async
          />
        </Grid>
      </Grid>

      <Footer />
    </>
  );
};

export default Cookie;
