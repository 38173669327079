import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Company/ModalitySrl.css";

interface ModalitySrlProps {}

const ModalitySrl: React.FC<ModalitySrlProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="container-modality-company">
      <Grid item xs={10}>
        <Typography
          variant="h2"
          className="title-modality-company color-primary"
        >
          Scegli la società più adatta ai tuoi progetti
        </Typography>
      </Grid>
      <Grid item xs={12} className="container-content-modality-company">
        <Grid
          item
          xs={10}
          md={3.5}
          className="container-card-modality-compan"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="card-modality-company">
            <Typography
              variant="h5"
              className="title-card-company color-primary"
            >
              Semplificata
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="icon-modality" />
            </div>

            <Typography className="color-primary" style={{ marginTop: "20px" }}>
              Questa tipologia di srl è quella con{" "}
              <span style={{ fontWeight: "bold" }}>
                i costi di costituzione più bassi
              </span>
              , ma prevede{" "}
              <span style={{ fontWeight: "bold" }}>diversi vincoli</span>. Ad
              esempio il capitale sociale massimo è di € 9.999, lo statuto non è
              modificabile e non ammette altre società tra i soci.
            </Typography>
          </div>
        </Grid>

        <Grid
          item
          xs={10}
          md={3.5}
          className="container-card-modality-compan"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="card-modality-company">
            <Typography
              variant="h5"
              className="title-card-company color-primary"
            >
              Ordinaria
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="icon-modality" />
              <div className="icon-modality" />
            </div>

            <Typography className="color-primary" style={{ marginTop: "20px" }}>
              È la forma di società{" "}
              <span style={{ fontWeight: "bold" }}>
                più utilizzata in Italia
              </span>
              , adatta a tutte le attività. Non ha limiti alle caratteristiche
              dei soci e al capitale sociale, inoltre il patrimonio personale
              dei soci resta separato da quello dell’impresa.
            </Typography>
          </div>
        </Grid>

        <Grid
          item
          xs={10}
          md={3.5}
          className="container-card-modality-compan"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="card-modality-company">
            <Typography
              variant="h5"
              className="title-card-company color-primary"
            >
              Innovativa
            </Typography>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="icon-modality" />
              <div className="icon-modality" />{" "}
              <div className="icon-modality" />
            </div>

            <Typography className="color-primary" style={{ marginTop: "20px" }}>
              È adatta a chi realizza{" "}
              <span style={{ fontWeight: "bold" }}>
                prodotti o servizi innovativi e ad alto valore tecnologico
              </span>
              . Presenta tutti i vantaggi di una Srl tradizionale, con la
              possibilità di accesso a particolari agevolazioni.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalitySrl;
